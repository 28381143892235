import { chamarApi } from "../../services/service.api";

export function Total (data, callback) {
    
    const getResult = async () => {
        try {
            
            const result = await chamarApi ({type: 'get', endpoint: 'questoesTotal', data: data}) // chama a API
            
            callback (result);
        
        } catch (error) {
            
            callback (error);
        
        } 
    }
        getResult();
    }

