import { chamarApi } from "../../../services/service.api";

export function Cadastrar (data, callback) {
    
    const getResult = async () => {
        
        try {
            
            const result = await chamarApi ({type: 'post', endpoint: 'alunoCadastro', data: data}) // chama a API
            
            callback (result);
            
        } catch (error) {
            
            callback (error);
        } 
    }
        getResult();
}