

import * as modelAluno from './model.aluno.cadastro'

export async function Cadastrar (dados, setLoading, setTela) {
    
    modelAluno.Cadastrar (dados, async function (result) {
      
      setLoading (result);
      
      if (result.isSuccess) {
        
        setTela (3)
        
      }

      setLoading (result);
      
  })
    
}