import { Grid, Typography, Divider } from "@mui/material"

export default function TituloGabaritoComentado (props) {
    
    const { gabarito_comentado, gabarito_objetiva } = props
    
    return <>
    
    {gabarito_comentado &&
        
        <Grid item xs={12} sx={{mt: gabarito_objetiva ? 3 : 0}} >
        
            <Typography variant="subtitle2" sx={{color: 'text.secondary'}}>
            
                    Resolução

            </Typography>
            
            <Divider sx={{mt:1}}/>
        
        </Grid>
        }
    </>
}