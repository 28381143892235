import { Tooltip, Avatar, Chip, Grid, Typography, IconButton } from "@mui/material"
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import SentimentSatisfiedOutlinedIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import SentimentVeryDissatisfiedOutlinedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';

export default function Titulo (props) {
    
    
    return (


        <>
        <Tooltip title={'Questão ' + props.titulo} arrow>
        <Chip sx={{backgroundColor: props.chipColor}} icon = {<AssignmentOutlinedIcon sx={{color: props.color}}/>} label = {props.titulo} />
        </Tooltip>
        {props.resultadoTela && props.tipo === 1 ?
        <>
        {props.resultado.status.lista_encerrada &&
        <>
        <Tooltip title={tooltip(props)} arrow>
        <Chip sx={{backgroundColor: props.chipColor, ml:1}} icon = {usuarioIcon(props)} label = {props.resposta ? props.resposta : "X" } />
        </Tooltip>
        
        <Tooltip title={'Essa é a resposta certa'} arrow>
        <Chip sx={{backgroundColor: props.chipColor, ml:1}} icon = {<ThumbUpAltOutlinedIcon  sx={{color: props.color}} />} label = {props.gabarito_objetiva} />
        </Tooltip>
        </>
}
        </>
        : null}
        </>
    )
}

function tooltip (props) {
   
    if (!props.resposta) return 'Você não respondeu essa questão'
    if (props.gabarito_objetiva === props.resposta) return 'Essa é a sua resposta e está correta!' 
    return 'Essa é a sua resposta e você não acertou'


}

function usuarioIcon (props) {

    if (props.tipo === 1 && props.gabarito_objetiva === props.resposta ) {
    
        return (
            
                <SentimentSatisfiedOutlinedIcon sx={{color: props.color}} />
            
        )
    } else {
        return (
            
                <SentimentVeryDissatisfiedOutlinedIcon sx={{color: props.color}} />
            
        )
    }
}