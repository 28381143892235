import { chamarApi } from "../../../../services/service.api";

export function Resposta (data, callback) {
    
    const getResult = async () => {
        
        try {
            
            const result = await chamarApi ({type: 'patch', endpoint: 'alunoQuestoes', data: data}) // chama a API
            
            callback (result);
            
        } catch (error) {
            
            callback (error);
        } 
    }
        getResult();
}