import { chamarApi } from "../../services/service.api";

export function LoginCadastrar (data, tipo, callback) {
    
    const getResult = async () => {
        
        try {
            
            const result = await chamarApi ({type: 'post', endpoint: tipo, data: data}) // chama a API
        
            callback (result);
            
        } catch (error) {
            
            callback (error);
        } 
    }
        getResult();
    }

