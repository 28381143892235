// REACT
import { useState } from 'react'; 

// MUI
import { ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material';
import MoveDownOutlinedIcon from '@mui/icons-material/MoveDownOutlined';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';

export default function ArranjoEnunciadoGabarito(props) {
  const [alignment, setAlignment] = useState('left');

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
    props.onChange(newAlignment);
  };

  return (
    <ToggleButtonGroup
      value={alignment}
      exclusive
      onChange={handleAlignment}
      aria-label="text alignment"
      size="small"
    >
      <Tooltip title='Enunciado e Resolução lado a lado' arrow>
      <ToggleButton  value="left" aria-label="left aligned">
        <GridViewOutlinedIcon />
      </ToggleButton>
      </Tooltip>
      
      <Tooltip title='Resolução abaixo do Enunciado' arrow>
      <ToggleButton value="right" aria-label="centered">
        <MoveDownOutlinedIcon />
      </ToggleButton> 
      </Tooltip>
    </ToggleButtonGroup>
  );
}
