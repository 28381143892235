import * as ModelMensagem from './model.contato'

export function Enviar (e, dados, loading, setLoading, usuario, setOpen) {
    e.preventDefault();
    
    // CHAMA A API
    setLoading ({...loading, inicio: true, fim: false, isSuccess: false})
  
    let data = {id_usuario: usuario.id_usuario, texto: dados.msg};
    
    ModelMensagem.Enviar (data, async (result) => {
            
      if (result.isSuccess) {
          
        console.debug ('Mensagem enviada com sucesso');
        setLoading ({...loading, inicio: false, fim: true, isSuccess: true, modalMsg: "Mensagem enviada com sucesso! Entraremos em contato o mais breve possível."});
      
      } else {
        
        console.debug ('Erro ao enviar a mensagem: ', result.erro + ' - ' + result.msg);
        setLoading ({...loading, inicio: false, fim: true, isSuccess: false, modalMsg: "Erro ao enviar a mensagem. Tente novamente mais tarde."});
        
      }
      
      setOpen (true); 
  })   
      }