import { useContext, useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { VariaveisContext } from "../../../services/service.variaveis.provider";
import { TemasContext } from "../../../services/service.temas.provider";
import { QuestoesContext } from "../../../services/service.questoes.provider";

import { Card, CardContent, Grid } from "@mui/material";

import ModalSucessoErro from "../../../components/ModalSucessoErro/ModalSucessoErro2";
import * as Btn from './controller.botoes.navegacao'

// BOTOES
import BotaoQuestaoAnterior from "./view.botao.questao.anterior";
import BotaoQuestaoProxima from "./view.botao.questao.proxima";
import BotaoSelecionarRemover from "./view.botao.selecionar.remover";
import ChipEscolhidas from "./view.chip.escolhidas";
import BotaoRemoverTodas from "./view.botao.remover.todas";
import BotaoReportarProblema from "./view.botao.reportar.problema";
import ModalSemCreditos from "../view.modal.sem.creditos.questoes";

 
function BotoesNavegacaoQuestoes (props) {
    
   const navigate = useNavigate ();
   const { isMobile } = useContext (VariaveisContext);
   const { theme } = useContext (TemasContext);

   const { usuario, questoes, questoesControle, setQuestoesControle, questoesEscolhidas, setQuestoesEscolhidas  } = useContext (QuestoesContext);
           
   const [btnLeftDisabled, setBtnLeftDisabled] = useState (true);
   const [btnRightDisabled, setBtnRightDisabled] = useState (questoesControle.total === 1 ? true : false);
   const [openModal, setOpenModal] = useState (false);
   const [loading, setLoading] = useState({inicio: false, fim: false, isSuccess: false});
   const [snack, setSnack] = useState (false);
   const snackValores = useRef (null);
   
   var atual = questoesControle.atual; 
   var total = questoesControle.total;
   var total_parcial = questoesControle.total_parcial;
    
    useEffect (() => {
      
      
      if (questoesControle.atual === questoesControle.total) {
        setBtnRightDisabled (true);
      }

      if (questoesControle.atual === 1) {
        setBtnLeftDisabled (true);
      }

      if (questoesControle.atual > 1 && btnLeftDisabled) {
        setBtnLeftDisabled (false);
      }

      if (questoesControle.atual > 0 && btnRightDisabled) {
        setBtnRightDisabled (false);
      }

      const anchor = document.querySelector(
        '#back-to-top-anchor',
      );
      if (anchor) {
        anchor.scrollIntoView({
          block: 'center',
        });
      }
      
    }, [questoesControle.atual]);

    function onClickComprar () {
      setOpenModal (false);
      navigate ('/creditos', {state: "questoes"})
    }
    
    

    return (<>
       
 
        {isMobile ? 
         
          
          <Grid container sx={{mt:0, mb:1, px:3, backgroundColor: `${theme.palette.background.default}`, borderTopLeftRadius:8, borderTopRightRadius:8}} direction="row" alignItems="center" spacing={1} justifyContent={"center"}>
              
              {props.btnGerar &&
              <Grid item xs={3} sx={{ml:0}}>
              {props.btnGerar}
              </Grid>
}

              <BotaoQuestaoAnterior   onClick={(e) => Btn.handleQuestaoAnterior (e, atual, total, total_parcial, setLoading, props, setQuestoesControle, questoesControle, questoesEscolhidas, questoes, loading)} disabled={btnLeftDisabled}  isMobile = {isMobile} />
              <BotaoQuestaoProxima    onClick={(e) => Btn.handleProximaQuestao(e, atual, total, total_parcial, setLoading, props, setQuestoesControle, questoesControle, questoesEscolhidas, questoes, loading)}  disabled={btnRightDisabled} isMobile = {isMobile}/>
              
              <BotaoSelecionarRemover onClickSelecionar = {(e) => Btn.handleSelecionar (e, questoesControle, usuario, setOpenModal, questoesEscolhidas, questoes, setQuestoesControle, setQuestoesEscolhidas, snackValores, setSnack, atual)} 
                                      onClickRemover = {(e) => Btn.handleRemover (e, questoesEscolhidas, setQuestoesEscolhidas, setQuestoesControle, snackValores, setSnack, atual, questoes, questoesControle)} isMobile = {isMobile} escolhida = {questoesControle.escolhida} numEscolhidas = {questoesControle.numEscolhidas} />
              
              <ChipEscolhidas         numEscolhidas = {questoesControle.numEscolhidas} isMobile = {isMobile} />              

            </Grid>
           
               
                
                       
        :   <Card  sx={{mt:-1.5, ml: -1, mr: -2, borderRadius:0, backgroundColor: `${theme.palette.background.default}`,}} >
            
              <CardContent  sx={{mt:-1.5, mb:-1.5,}}>
              
              <Grid container    direction="row" alignItems="center" justifyContent="center" spacing={3} >
                
                <BotaoQuestaoAnterior onClick={(e) => Btn.handleQuestaoAnterior(e, atual, total, total_parcial, setLoading, props, setQuestoesControle, questoesControle, questoesEscolhidas, questoes, loading)} disabled={btnLeftDisabled} />
                <BotaoQuestaoProxima  onClick={(e) => Btn.handleProximaQuestao(e, atual, total, total_parcial, setLoading, props, setQuestoesControle, questoesControle, questoesEscolhidas, questoes, loading)}  disabled={btnRightDisabled} />
                
                <BotaoSelecionarRemover onClickSelecionar = {(e) => Btn.handleSelecionar (e, questoesControle, usuario, setOpenModal, questoesEscolhidas, questoes, setQuestoesControle, setQuestoesEscolhidas, snackValores, setSnack, atual)} 
                                        onClickRemover = {(e) => Btn.handleRemover (e, questoesEscolhidas, setQuestoesEscolhidas, setQuestoesControle, snackValores, setSnack, atual, questoes, questoesControle)} isMobile = {isMobile} escolhida = {questoesControle.escolhida} />
                
                <ChipEscolhidas numEscolhidas = {questoesControle.numEscolhidas} isMobile = {isMobile} />

                <BotaoRemoverTodas onClick = {(e) => Btn.handleRemoverTodas (e, setQuestoesEscolhidas, setQuestoesControle, snackValores, setSnack, questoesControle)} />

                <BotaoReportarProblema variant="text" isMobile = {isMobile} questao = {questoes [questoesControle.atual-1].id}/>
                
              </Grid>
              
              </CardContent>
            
            </Card>
        
}
        {/* Alerta de erro em caso do número de questões escolhidas for maior que o numero de créditos */}
      {openModal &&       
      <ModalSemCreditos open={openModal} setOpen={setOpenModal} questoes_total = {questoesEscolhidas.length + 1} creditos={usuario.numcreditos} handleComprarCreditos={() => onClickComprar()} />
      
}
      
      </>  
    );
} export default BotoesNavegacaoQuestoes;