export function Finalizar (response, metodo, setPixPage, setPaymentError, setPaymentMsg, setPaymentSuccess, navigate, usuario, setUsuario) {
    console.log (response)   
    if (response !== null && response !== undefined) {
    
      // PAGAMENTO BEM SUCEDIDO
       if (response.isSuccess && response.statusCode === 201) {
        
        // PIX
        if (metodo === 'pix') {
          
          localStorage.setItem ('pix', JSON.stringify ({id: response.response.id_pagamento, code: response.response.qr_code, qr_code: response.response.qr_code_base, encerrado: false}))
          setPixPage (true);
          return;

        }
        
        // CARTÃO DE CRÉDITO	
        if (metodo !== 'pix') {
          
          setCreditos (usuario, setUsuario, response.response.total)
                    
          setPaymentMsg ('Pagamento efetuado com sucesso! Os seus créditos já estão prontos para o uso.');
          setPaymentSuccess (true);
          return;

        } 
        
    } else {
      
      if (response.statusCode === 400) {
        let a = response.msg ? response.msg : 'erro desconhecido'
        setPaymentMsg ('Pagamento não autorizado (' + a + ').');
        setPaymentError (true);
        return;

      }
    }
       
  } else {
    
    
    setPaymentMsg ('Ocorreu um erro na comunicação com os nossos servidores.');
    setPaymentError (true);

  }
}

export function PIXFinalizar (valor, setPaymentMsg, setPaymentSuccess, usuario, setUsuario) {

  if (valor) {
    setCreditos (usuario, setUsuario, usuario.numcreditos)
    setPaymentMsg ('Pagamento efetuado com sucesso! Os seus créditos já estão prontos para o uso.');
    setPaymentSuccess (true);
    return;
  }


}
export function onReady (valor, setLoading) {
    
    if (valor) {
    setLoading ({inicio: false, fim: true, isSuccess: true})
    
    }
  }

  function setCreditos (usuario, setUsuario, creditos) {

    let a = {...usuario};
          a.numcreditos = a.numcreditos + creditos
          localStorage.setItem ('usuario', JSON.stringify (a))
          setUsuario (a);

  }