
import * as React from 'react';
import { ReactComponent as Icone_fisica_em} from '../../data/images/fis.svg';
import { ReactComponent as Icone_quimica_ef} from '../../data/images/quim_ef2.svg';
import { ReactComponent as Icone_portugues_ef} from '../../data/images/port_ef2.svg';
import { ReactComponent as Icone_geografia_ef} from '../../data/images/geo_ef2.svg';
import { ReactComponent as Icone_historia_ef} from '../../data/images/hist_ef2.svg';
import { ReactComponent as Icone_ciencias_ef} from '../../data/images/cien_ef2.svg';
import { ReactComponent as Icone_mat_ef} from '../../data/images/mat_ef2.svg';
import { ReactComponent as Icone_ing_ef} from '../../data/images/ing_ef2.svg';
import { TemasContext } from '../../services/service.temas.provider';

export function VerificaSegmento(disciplinas, segmento) {
    
    for (let i = 0; i < disciplinas.length; i++) {
 
        if (disciplinas[i].segmento.nome === segmento) {
 
            return true;
        }
    }
 
    return false;
}

export function RetornaIcon (disciplina, id_segmento) {
    

    let a = "";
    if (disciplina === "Física" && id_segmento === 3) {return <> <Icone_fisica_em  fill={RetornaCores(disciplina).dark} viewBox="0 -20 300.000000 316.000000" /></>}
    if (disciplina === "Física" && id_segmento === 4) {return <> <Icone_fisica_em  fill={RetornaCores(disciplina).dark} viewBox="0 -20 300.000000 316.000000" /></>}
    if (disciplina === "Ciências" && id_segmento === 3) {return <> <Icone_ciencias_ef  fill={RetornaCores(disciplina).dark} viewBox="0 -20 300.000000 326.000000" /></>}
    if (disciplina === "Geografia" && id_segmento === 3) {return <> <Icone_geografia_ef  fill={RetornaCores(disciplina).dark} viewBox="0 -20 300.000000 326.000000" /></>}
    if (disciplina === "História" && id_segmento === 3) {return <> <Icone_historia_ef  transform= 'scale(1.0, 0.9)' fill={RetornaCores(disciplina).dark} viewBox="0 -10 280.000000 336.000000" /></>}
    if (disciplina === "Língua Portuguesa" && id_segmento === 3) {return <> <Icone_portugues_ef transform= 'scale(1.1, 1.2)' fill={RetornaCores(disciplina).dark} viewBox="-30 -30 530.000000 476.000000" /></>}
    if (disciplina === "Inglês" && id_segmento === 3) {return <><Icone_ing_ef  fill={RetornaCores(disciplina).dark} viewBox="0 -20 300.000000 326.000000" /></>}
    if (disciplina === "Química" && id_segmento === 3) {return <><Icone_quimica_ef  fill={RetornaCores(disciplina).dark} viewBox="0 -20 340.000000 340.000000" /></>}
    if (disciplina === "Matemática" && id_segmento === 3) {return <> <Icone_mat_ef  transform= 'scale(1.15, 1.35)' fill={RetornaCores(disciplina).dark} viewBox="0 -70 436.000000 386.000000" /></>}
    
}

export function RetornaCores (disciplina) {
  
    const {theme} = React.useContext (TemasContext);
  
    function cor () {
        if (disciplina === "Física") {return theme.palette.success.dark}
        if (disciplina === "Ciências" ) {return theme.palette.error.dark}
        if (disciplina === "Geografia" ) {return theme.palette.warning.dark}
        if (disciplina === "História" ) {return theme.palette.warning.dark}
        if (disciplina === "Língua Portuguesa" ) {return theme.palette.info.dark}
        if (disciplina === "Inglês") {return theme.palette.info.dark}
        if (disciplina === "Química" ) {return theme.palette.success.dark}
        if (disciplina === "Matemática" ) {return theme.palette.success.dark}
  }
    
    if (disciplina === "Física") {return {dark: cor(disciplina), light: 'success.light', main: 'success.main'}}
    
    if (disciplina === "Ciências" ) {return {dark: cor(disciplina), light: 'error.light', main: 'error.main'}}
    if (disciplina === "Geografia" ) {return {dark: cor(disciplina), light: 'warning.light', main: 'warning.main'}}
    if (disciplina === "História" ) {return {dark: cor(disciplina), light: 'warning.light', main: 'warning.main'}}
    if (disciplina === "Língua Portuguesa" ) {return  {dark: cor(disciplina), light: 'info.light', main: 'info.main'}}
    if (disciplina === "Inglês") {return{dark: cor(disciplina), light: 'info.light', main: 'info.main'}}
    if (disciplina === "Química" ) {return{dark: cor(disciplina), light: 'success.light', main: 'success.main'}}
    if (disciplina === "Matemática" ){return {dark: cor(disciplina), light: 'success.light', main: 'success.main'}}
    
}
