import { useState, useEffect } from "react";
import { TextField } from "@mui/material";

function FormNomeView (props) {
    
    const [value, setValue] = useState (props.value ? props.value : '');
    const focus = props.focus!==undefined ? props.focus : false;

    useEffect(() => {
        
        props.onChange (value);
    
    }, [value]);

    useEffect(() => {
    
        setValue (props.value);
    
    }, [props.value]);
    
    function onHandleChange (e) {
        
        if (e !== undefined) {
        
            e.preventDefault ();
            setValue (e.target.value);
            props.onChange (e);
        
    }
    }
    
    return (
    
    <>
    
    <TextField
    disabled = {props.disabled ? props.disabled : false}
    size="small"      
    required
    fullWidth={props.fullWidth}
    autoFocus={focus}
    margin="normal"
    name="nome"
    label="Nome"
    value={value}
    onChange={(e) => onHandleChange (e)}
    
    /> 
    </>

)} export default FormNomeView;

FormNomeView.defaultProps = {
    fullWidth: true
}