export function calcularNota (aluno) {
    let totalQuestoesTipo1 = 0;
    let acertos = 0;
    console.log (aluno)
    aluno.questoes.forEach (q => {
        
        if (q.tipo === 1) {
        
            totalQuestoesTipo1++;
            
            if (aluno.status.gabarito_liberado) {
            if (q.gabarito_objetiva === q.resposta) {
        
                acertos++;
        
            }}}})
        
            if (!aluno.status.gabarito_liberado) {
                if (aluno.acertos === undefined) {
                    aluno.acertos = 0;
                } else {
                    acertos = aluno.acertos;
                }
                
            }
    
   
    
    const porcentagemAcertos = (acertos / totalQuestoesTipo1) * 10;
    
    return ({total: totalQuestoesTipo1, acertos: acertos, pct: parseFloat(porcentagemAcertos.toFixed(1))});
}