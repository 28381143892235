"use strict";
import * as React from 'react';
import { Dialog, DialogContent, Box, CircularProgress, Alert, AlertTitle } from '@mui/material';
import SnackbarAviso from '../SnackBar/SnackBarAviso.jsx';


import ErroServidor from '../../views/components/view.erro.servidor.imagem.jsx';
import ModalSucessoErro from '../ModalSucessoErro/ModalSucessoErro2.jsx';

  
function Loading (props) {
  
  if (props.open===false) return;

  if (props.online===false && props.mostrarErroOnlineStatus) {
      
      return (
        <>
          <ErroServidor tipo='internet' />
        </>
      )} 

      if (props.loading.inicio && props.loading.isSuccess===false && props.local === 'full') {
  
        return (
          <>

      <Dialog open={props.loading.inicio}>
      <DialogContent>
          <Box display="flex" alignItems="center">
                  <CircularProgress size={40} color="primary" disableShrink/>
                  {props.msg === undefined ? null : <Box ml={3} sx={{fontSize: '1.1em'}}>{props.msg}</Box>}
         </Box>
      </DialogContent>
  </Dialog>
  </>
    )}


  if (props.loading.inicio===true && props.loading.isSuccess===false && props.local === 'conteudo') {
  
    return (
      <>
        
              <Box display="flex" alignItems="center" justifyContent="center" sx = {{flexDirection: 'column',  minHeight: '75vh'}}>
                 
                  <CircularProgress size={40} color="primary" disableShrink/>
                    {props.msg === undefined ? null : <Box ml={3} sx={{fontSize: '1.2em', mt:2}}>{props.msg}</Box>}
              </Box>

      </>
    )}

    if (props.loading.inicio && props.loading.isSuccess===false && props.local === 'componente') {
  
      return (
        <>
          
          <Box display="flex" alignItems="center" justifyContent="center" sx = {{flexDirection: 'column', mb:4, mt:4}}>
                 
                 <CircularProgress size={40} color="primary" disableShrink/>
                   {props.msg === undefined ? null : <Box ml={3} sx={{fontSize: '1.2em', mt:2}}>{props.msg}</Box>}
             </Box>
  
        </>
      )}
    
      if (props.online && props.loading.inicio===false && props.loading.fim===true && props.loading.isSuccess === false && props.tipo === 'imagem') {
  
        return (
          <>
            <ErroServidor tipo='servidor' />
          </>
        )}
        
      if (props.online && props.loading.inicio===false && props.loading.fim && props.loading.isSuccess === false && props.tipo === 'alerta') {

        return (
          <>
            <Alert severity="error" >
              <AlertTitle>
              {props.loading.erro}
              </AlertTitle>  
                {props.loading.msg}
            </Alert>
        
          </>
        )}

        if (props.online===true && props.loading.inicio===false && props.loading.fim===true && props.loading.isSuccess===true && props.tipoSucesso === 'modal') {

          return (
            <>
            
              <ModalSucessoErro tipo='success' open={true} msg={props.msgSucesso ? props.msgSucesso : null} componente = {props.componente ? props.componente : null} onClose={() => props.onClose()} />

          
            </>
          )}
          
          if (props.loading.fim===true && props.tipoSucesso === 'snack') {

            return (
              <>
              
              <SnackbarAviso open={props.loading.fim} isAlert={true} msg={props.loading.isSuccess ? props.snackMsg.success : props.snackMsg.error }  posicao={{vertical: "bottom", horizontal: "center"}} onClose={() => props.onClose()} time={3000} variant='filled' 
                tipo={props.loading.isSuccess ? "success" : "error"} 
                
                />
  
            
              </>
            )}
     
} export default Loading;

