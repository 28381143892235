import { Grid, Link } from '@mui/material';

export default function EsqueciSenha ({isLoading, esqueciSenha}) {

    return (

        <>
        
        <Grid item>
        
            <Link href="#" variant="body2" onClick={esqueciSenha}>
            
                {isLoading ? null : "Esqueceu a senha?"}
            
             </Link>
        
        </Grid>
        
        </>
    )
}