import { Typography } from "@mui/material"

export default function Titulo (props) {
    return (
        <>
        
        <Typography component="h1" variant="h5">
            
            {props.titulo}
        
        </Typography>

        </>
    )
}