import { Alert, AlertTitle, Grid, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormGroup, FormControlLabel, Switch, Divider } from '@mui/material';
import CheckCircleOutlineSharpIcon from '@mui/icons-material/CheckCircleOutlineSharp';

export default function BaixarProvaView (props) {
    
    const { open, baixado } = props 
    console.count ('Baixar Prova View')
    return (
    <>
    
    <Dialog open={open} >
      
      <DialogTitle>
      
        Lista produzida com sucesso!
        <Divider /> 
      
      </DialogTitle>
      
      <DialogContent>
      
        <DialogContentText>
      
        <Grid container display="flex" flexDirection={"column"} alignItems="center" justifyContent="center">
      
                    <Grid item xs={12} >
      
                        <CheckCircleOutlineSharpIcon color="success" sx={{fontSize: "5em"}}></CheckCircleOutlineSharpIcon>
      
                    </Grid>
          
                    {!baixado &&
                    <Grid item xs={12} mt={3} >
      
                        Clique em BAIXAR para obter o arquivo da lista como documento do Word. 
      
                    </Grid>
}
        </Grid>  
        
        </DialogContentText>
        
        {baixado &&
    
        <Alert sx={{mt:2}} severity="success" >
         
            <AlertTitle>
         
              Finalizado!
         
            </AlertTitle>  
         
              O arquivo das questões se encontra na pasta Downloads
        </Alert>
        }    
    
    </DialogContent>
    
    <DialogActions>
                
        <Button onClick={(e) => props.onClose (e)} disabled={!baixado}>Sair</Button>
        <Button onClick={(e) => props.handleBaixar (e)} disabled={baixado} >Baixar</Button>

    </DialogActions>
    
    </Dialog>
    
    </>
    )
}