import { useEffect, useState } from "react";
import {AppBar, Box, Tab, Tabs, Typography, Collapse } from "@mui/material";
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import PropTypes from 'prop-types';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useLocalStorage } from "../../../../services/service.hooks";
import Enunciado from "../Enunciado/modulo.enunciado";
import Opcoes from "../Opcao/modulo.opcao";
import BotaoQuestaoAnterior from "../view.botao.questao.anterior";
import BotaoQuestaoProxima from "../view.botao.questao.proxima";
import { containerStyle, getQuestoesValidas, getQuestoesRespondidas } from "../controller.aluno.lista";
import { useTheme } from "@mui/material";
import GabaritoComentado from "./view.gabarito.comentado";
import Nota from "./Nota/Nota";
import GabaritoNaoLiberado from "../../GabaritoNaoLiberado/modulo.gabarito.nao.liberado";
import MsgNota from "../../NotaMsg/modulo.msg.nota";
import { calcularNota } from "./Nota/controller.nota";

export default function AlunoResultado (props) {
   
    //const [resultado] = useState (props.resultado.nota === undefined ? calcularNota () :)
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const lineColor = theme.palette.cinza.main
    const [ aluno, setAluno ] = useLocalStorage ('aluno')
    
    const questao = aluno.questoes
    
    const [ atual, setAtual ] = useState (1);
    
    
    const [ anteriorDisabled, setAnteriorDisabled ] = useState (true);
    const [ proximaDisabled, setProximaDisabled ] = useState (false);
    
    const [tabValue, setTabValue] = useState(0);
    const [ mostrar, setMostrar]  = useState(false);
    
    const total         = questao.length;
    
    const [fadeKey, setFadeKey] = useState(0);
    const [checked, setChecked] = useState(false);

    useEffect(() => {
        setFadeKey(prevKey => prevKey + 1);
        setChecked (true);
      }, [atual]);
    
    useEffect (() => {

        if (atual === total) {

            setProximaDisabled (true)
        } else {
            setProximaDisabled (false)
        }
        
        if (atual === 1) {
            setAnteriorDisabled (true)
        } else {
            setAnteriorDisabled (false)
    }
    }, [atual])
    
    const handleChangeTab = (event, newValue) => {
      
        setTabValue (newValue);

      };

    return (<>
        
        {!mostrar &&
        <MsgNota onClose={() => setMostrar(true)} {...props}/>
        }
        
        
        <Box sx={{ maxWidth: '800px', margin: '0 auto', typography: 'body1', borderLeft: '1px solid ' + lineColor, borderRight: '1px solid ' + lineColor,
            borderBottom: !isMobile ? '1px solid ' + lineColor : '0px none',  bgcolor: 'background.default',  }}>
        <AppBar position="sticky" sx={{ bgcolor: 'background.default',  boxShadow: 'none'}}>
        <Tabs  value={tabValue} onChange={handleChangeTab}  variant="fullWidth">
          
            
            

            <Tab label="Questões"  {...a11yProps(0)} />
            <Tab label="Resolução" {...a11yProps(1)} />
            <Tab label="Resultado" {...a11yProps(2)} />
            
           
            
          
            </Tabs>
            </AppBar>
            <TabPanel value={tabValue} index={0}>
            
            <Box style={containerStyle} >
               
               <BotaoQuestaoAnterior disabled = {anteriorDisabled} onClick = {() => setAtual (atual-1)} />
               <BotaoQuestaoProxima disabled = {proximaDisabled} onClick = {() => setAtual (atual+1)} />
               
     
               
               <Enunciado enunciado = {questao [atual - 1].enunciado} atual = {atual} total = {total} gabarito_objetiva = {questao [atual - 1].gabarito_objetiva} 
                          tipo = {questao [ atual - 1 ].tipo} resposta = { questao [atual - 1].resposta} resultado={props.resultado} resultadoTela = {props.resultadoTela} gabarito_liberado={props.gabarito_liberado} />
               
               
               <Box>
               <Opcoes    opcoes    = {questao [atual - 1].opcoes} resultado = {true}  resultadoTela = {props.resultadoTela} aluno = {aluno} setAluno = {setAluno} atual = {atual} gabarito_liberado = {props.gabarito_liberado}  />
               </Box>
               

               </Box>         
                           
                
            
        

            </TabPanel>
          
          <TabPanel value={tabValue} index={1}>

            {props.gabarito_liberado ?
              <GabaritoComentado texto = {questao [atual - 1 ].gabarito_comentado} atual = {atual} />
              : 
              <GabaritoNaoLiberado resultado={props.resultado}/>
            }

   
          </TabPanel>
   
        <TabPanel value={tabValue} index={2}>
          <Box mt={1} mb={0} mr={1}>
          <Nota {...props} />
          </Box>
        </TabPanel>
       
        
        
               
        
        </Box>
        </>
        );
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        
      role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 1}} >
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }
  