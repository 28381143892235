import { chamarApi } from "../../services/service.api";
import * as React from 'react'
import { OnlineStatus } from '../../services/service.hooks'

export function Gerar (data, callback) {
    
    const getResult = async () => {
        
        try {
            
            const result = await chamarApi ({type: 'post', endpoint: 'linkGeracao', data: data}) // chama a API
            
            callback (result);
            
        } catch (error) {
            
            callback (error);
        } 
    }
        getResult();
}

export function Encerrar (data, callback) {
    
    const getResult = async () => {
        
        try {
            
            const result = await chamarApi ({type: 'patch', endpoint: 'link', data: data}) // chama a API
            
            callback (result);
            
        } catch (error) {
            
            callback (error);
        } 
    }
        getResult();
}

export function Editar (data, callback) {
    
    const getResult = async () => {
        
        try {
            
            const result = await chamarApi ({type: 'patch', endpoint: 'link', data: data}) // chama a API
            
            callback (result);
            
        } catch (error) {
            
            callback (error);
        } 
    }
        getResult();
}

export function Deletar (data, callback) {
    
    const getResult = async () => {
        
        try {
            
            const result = await chamarApi ({type: 'post', endpoint: 'linkDeletar', data: data}) // chama a API
            
            callback (result);
            
        } catch (error) {
            
            callback (error);
        } 
    }
        getResult();
}

export function Liberar (data, callback) {
    
    const getResult = async () => {
        
        try {
            
            const result = await chamarApi ({type: 'get', endpoint: 'link', data: data}) // chama a API
            
            callback (result);
            
        } catch (error) {
            
            callback (error);
        } 
    }
        getResult();
}

export function Listar (data, callback) {
    
    const getResult = async () => {
        
        try {
            
            const result = await chamarApi ({type: 'get', endpoint: 'link', data: data}) // chama a API
            
            callback (result);
            
        } catch (error) {
            
            callback (error);
        } 
    }
        getResult();
}



export function Stats (data, callback) {
    
    const getResult = async () => {
        
        try {
            
            const result = await chamarApi ({type: 'get', endpoint: 'link', data: data}) // chama a API
            
            callback (result);
            
        } catch (error) {
            
            callback (error);
        } 
    }
        getResult();
}

export function useLinkStats (params, fetch, setLoading, questoes) {
    
    const [shouldFetch, setShouldFetch] = React.useState (fetch);  
    const [data, setData] = React.useState (null);
    const loading = React.useRef ({});
        
    const onlineStatus = OnlineStatus();

    const fetchData = React.useCallback(async () => {
        
        try {
        
            console.debug ('Listar disciplinas');
            
            let a = questoes.length === 0 ? { type: 'get', endpoint: 'linkStatsQuestoes', data: params } : { type: 'get', endpoint: 'linkStats', data: params }
            
            const result = await chamarApi(a);
        
            if (result.isSuccess) {
            
                setData (result.response);
                console.log (result.response)

            } else {

                setData ([]);        

            }

            loading.current = {inicio: result.inicio, fim: result.fim, isSuccess: result.isSuccess, online: true, erro: result.erro, msg: result.msg}
        
            setLoading (loading.current) 

        } catch (error) {
        
            setData ([]);
            loading.current = {inicio: error.inicio, fim: error.fim, isSuccess: error.isSuccess, online: true,  erro: error.erro, msg: error.msg};
        
            setLoading (loading.current) 

        } finally {

            setShouldFetch(false);
        
        }

    }, []); // Dependências do useEffect
    
    
    React.useEffect (() => {
    
        if (onlineStatus) { 
        
            loading.current = {inicio: true, fim: false, isSuccess: false, online: true};
            setLoading (loading.current) 
            setShouldFetch(true)

        } 
        
    }, [onlineStatus])

    React.useEffect(() => {
    
    if (onlineStatus===false) {
        
        loading.current = {inicio: false, fim: true, isSuccess: false, online: false, erro: null, msg: null};
        setLoading (loading.current)
        setShouldFetch(false);
        
        return 
    }
    
    if (shouldFetch && onlineStatus) {
        
        fetchData ();

    }}, [shouldFetch]);

    

        
    
    
    // const refetch = () => setShouldFetch(true);
    
    return [ data ];
}
   