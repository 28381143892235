
import { useEffect, useState } from "react";
import { ObterPorIDLista as ObterQuestoes } from "../Questoes/Questao/model.questao";
import Loading from '../../components/Loading/view.loading2.jsx';
import { Get, Set } from "../../services/service.local-storage";

export function ControllerVerQuestao ({dados, setQuestoes, setLiberado, Montar}) {

    const [loading, setLoading] = useState ({inicio: true, fim: false, isSuccess: false, online: true })
    
    useEffect (() => {
        
        ObterQuestoes (dados, async (result) => {

            if (result.isSuccess) {
                
                let b = Get ('listas_questoes', 'array')
                b.push ({id_lista: dados.id_lista, questoes: result.response})
                Set ('listas_questoes', b, 'array')

                let a = Montar(result.response)
                setQuestoes (a)
                
                

                setLoading ( {...loading, inicio: false, fim: true, isSuccess: true, online: true})
                setLiberado (true)
                
                
            } else {
                
                setQuestoes ([])
                setLoading ({...loading, inicio: false, fim: true, isSuccess: false, online: true})
               
                console.error ('Não foi possível recuperar as questões', result.erro)
            }
        }
    )

    },[])
    
    

return (<>

  <Loading local="conteudo" loading={loading} online={loading.online} tipo='imagem' msg={"Buscando as questões..."} mostrarErroOnlineStatus /> 

</>)
}  
