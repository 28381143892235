import { useContext, useState } from "react";
import * as modelNovoLink from './model.lista.link'
import Loading from '../../components/Loading/view.loading2.jsx';
import ModalSucessoErro from "../../components/ModalSucessoErro/ModalSucessoErro";
import { useLocalStorage } from "../../services/service.hooks";
import { QuestoesContext } from "../../services/service.questoes.provider";

export default function ControllerListaDeletar (props) {
    
    const { usuario } = useContext (QuestoesContext)
    const [loading, setLoading] = useState({inicio: false, fim: false, isSuccess: false});
    const [ listas, setListas ] = useLocalStorage ('listas')
    

    function chamarApi () {
        
        let dados = {}
        dados = {id_link: props.id_link, id_usuario: usuario.id_usuario, questoes_total: props.questoes_total }
        
        console.log (dados);
        modelNovoLink.Deletar (dados, async function (result) {
            
            if (result.isSuccess) {
               
               let a = [...listas]
               a = a.filter(obj => obj.lista_links.id_link !== props.id_link); 
               setListas (a)
               setLoading ({...loading, inicio: false, fim: true, isSuccess: true, online: true})
                
            } else {
                
                setLoading ({...loading, inicio: false, fim: true, isSuccess: false})
                console.log ('Erro ao cadastrar novo link', result)
                props.setOpenDeletar (false)
                
            }

           
        })
        
    }

    function handleBtn2 () {
        
        setLoading ({...loading, inicio: true, online: true})
        chamarApi();

    }

    function onCloseModal () {
        window.location.reload();
        props.setOpenDeletar (false)
    }


    return (
        <>
        
        {/* ************** LOADING ************** */}
        {props.open===true && 
            <Loading local="full" loading={loading}  tipo='imagem' online={loading.online} snackMsg={{success: 'Link deletado com sucesso!', error: 'Houve um erro ao tentar deletar o link'}}
                    msg={"Deletando..."} mostrarErroOnlineStatus tipoSucesso='snack' onClose={() => onCloseModal()}
                               
            />
        } 
        
        {props.open===true && loading.inicio===false && loading.fim===false &&
        <ModalSucessoErro open={props.open} titulo='Deletar o link' msg='Todas os resultados dos alunos desta turma serão apagados. Deseja realmente deletar?' success={false}
          data={{btn1: {label: "VOLTAR", color: "secondary", mostrar: true}, btn2: {label: "DELETAR", color: "primary", mostrar: true} , errorTipo: "warning"}} 
          onClose={() => props.setOpenDeletar (false)}
          onClickBtn2={() => handleBtn2()}
        />}
        


        </>
    )

}



