import { chamarApi } from '../../services/service.api';
import * as React from 'react';
import { OnlineStatus } from '../../services/service.hooks'

export function useDisciplinas (params, fetch, setLoading) {
    
    const [shouldFetch, setShouldFetch] = React.useState (fetch);  
    const [data, setData] = React.useState (null);
    const loading = React.useRef ({});
        
    const onlineStatus = OnlineStatus();

    const fetchData = React.useCallback(async () => {
        
        try {
        
            console.debug ('Listar disciplinas');
        
            const result = await chamarApi({ type: 'get', endpoint: 'disciplinasListar', data: params });
        
            if (result.isSuccess===true) {
            
                setData (result.response);
                loading.current = {inicio: false, fim: true, isSuccess: true, online: true}

            } else {

                setData ([]);        
                loading.current = {inicio: false, fim: true, isSuccess: false, online: true, erro: result.erro, msg: result.msg}
            }

            //loading.current = {inicio: result.inicio, fim: result.fim, isSuccess: result.isSuccess, online: true, erro: result.erro, msg: result.msg}
        
            setLoading (loading.current) 

        } catch (error) {
            console.log (error)
            setData ([]);
            loading.current = {inicio: false, fim: true, isSuccess: false, online: true,  erro: error.erro, msg: error.msg};
        
            setLoading (loading.current) 

        } finally {

            setShouldFetch(false);
        
        }

    }, []); // Dependências do useEffect
    
    
    React.useEffect (() => {
    
        if (onlineStatus) { 
        
            loading.current = {inicio: true, fim: false, isSuccess: false, online: true};
            setLoading (loading.current) 
            setShouldFetch(true)

        } 
        
    }, [onlineStatus])

    React.useEffect(() => {
    
    if (onlineStatus===false) {
        
        loading.current = {inicio: false, fim: true, isSuccess: false, online: false, erro: null, msg: null};
        setLoading (loading.current)
        setShouldFetch(false);
        
        return 
    }
    
    if (shouldFetch && onlineStatus) {
        
        fetchData ();

    }}, [shouldFetch]);

    

        
    
    
    // const refetch = () => setShouldFetch(true);
    
    return [ data ];
}
    
   
    
   