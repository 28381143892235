import { Grid, Chip } from "@mui/material"

export default function FonteAno (props) {
    const { fonte_ano } = props 
    
    return <>
    
    {fonte_ano && 
        <>
            <Grid item xs={12} >
            
                <Chip label={fonte_ano} sx={{fontSize: '0.8em', fontWeight: 400, mb:1, mt:-1}} />
            
            </Grid>
        </>
        }
    
    </>
}