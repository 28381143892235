import Card from "../../../views/components/view.card";
import CardTitulo from "../../../views/components/view.card.titulo";
import { FormControl, Grid, Box } from "@mui/material";
import RadioBoxView from "../../../views/forms/view.form.radio.box";
import SelectMultipleView from "../../../views/forms/view.form.select.multiple";
import Letra from "../../../views/components/view.letra";
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import AccordionCustom from "../../../views/components/view.accordion";

function FiltrosView (props) { 
     
    const propriedades = props.filtros.propriedades;
    const opcoes = props.filtros.opcoes;
    const selected = props.filtros.selected;
           
    return (
        <>
        
       
        
               
        <AccordionCustom titulo={<CardTitulo icon={<FilterAltOutlinedIcon  />} titulo = 'Filtros' />} variant={'body1'} fontSizeTitulo={'1.1rem'} nome='Filtros'  borderRadius={18} divider={true} key="Filtros"componentes = {<>   

                <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', ml:0, mr:0, mb:1, mt:4}}  > 
                    
                    {(props.loading.inicio=== false  && props.loading.fim && props.loading.isSuccess) &&  
    
                    
                    
                            <Grid container spacing={1} >
                    
                                <Box sx={{mt:1}}>
                    
                                    <Letra fontSize='0.8em' fontWeight={300} texto="todas as opções estão selecionadas por default" />     
                    
                                </Box>
                    
                    {propriedades.map ((filtro) => {
                            
                    if (filtro.tipo==='select') {
                            
                        return (
                    
                            <Grid item xs={12} lg={12}  sx={{mt:1.5}}>
                            
                                <SelectMultipleView key={"key_" + filtro.nome} titulo={filtro.titulo} nome={filtro.nome} opcoes={opcoes[filtro.nome]} selected={selected !==undefined ? selected[filtro.nome] : undefined } onChange={(nome, id) => props.onChange (nome, id)} /> 
                            
                            </Grid> 
                            )    
                            }
  
                    if (filtro.tipo==='radio') {
                        
                        return (
                        
                            <Grid item xs={12} lg={12} sx={{mt:1.5}}>
                            
                                <RadioBoxView key={"key_" + filtro.nome} titulo={filtro.titulo} nome={filtro.nome} label={filtro.label} onChange={(nome, id) => props.onChangeRadioBox (nome, id)} />
                            
                            </Grid>
                            )
                            }
                        })
                        }

                </Grid>
                
                }
                </Box>       
                </>
        } /> 
               
                    
     </>
    )

} export default FiltrosView;