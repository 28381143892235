import { Grid, Typography, Divider } from "@mui/material"

export default function TituloEnunciado () {

    return <>
    
    <Grid item xs={12} >
        
        <Typography variant="subtitle2" sx={{color: 'text.secondary'}}>
        
            Enunciado

        </Typography>
        
        <Divider sx={{mt:1}}/>
    
    </Grid> 
    </>
}