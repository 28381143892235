import logo from '../../data/images/logo_completo_dark.png';

export default function Logo (props) {


    return (
        <>
        
        <img style={{ width: props.isMobile ? '90%' : '350px', height: 'auto', objectFit: 'contain', paddingTop: '0.1em', paddingBottom: '0.1em' }} src={logo} alt="Logo" />

        </>
    )
}