import { useState } from 'react';
import { BarChart, barLabelClasses } from '@mui/x-charts';
import Card from '../../../views/components/view.card';
import { useTheme, Box, Grid, AccordionSummary, AccordionDetails, Typography, styled, Divider } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Enunciado from '../../../pages/Questoes/Questao/view.enunciado';
import MuiAccordion from '@mui/material/Accordion';
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined';
import CardTitulo from '../../../views/components/view.card.titulo';

export default function BarChartCustom (props) {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  
  console.log (props.questao)

  const theme = useTheme();

  function Opcao (letra) {
    if (letra === 'A') return 'a)'
    if (letra === 'B') return 'b)'
    if (letra === 'C') return 'c)'
    if (letra === 'D') return 'd)'
    if (letra === 'E') return 'e)'
  }

  function Cor () {
    let result = [];
    let verde = theme.palette.chart.verde;
    let vermelho = theme.palette.chart.vermelho;
    let resposta = Opcao(props.questao.gabarito_objetiva)

    if (resposta === 'a)') {
      result.push(verde)
    } else {
      result.push(vermelho)
    }
    if (resposta === 'b)') {
      result.push(verde)
    } else {
      result.push(vermelho)
    }
    if (resposta === 'c)') {
      result.push(verde)
    } else {
      result.push(vermelho)
    }
    if (resposta === 'd)') {
      result.push(verde)
    } else {
      result.push(vermelho)
    }
    if (resposta === 'e)') {
      result.push(verde)
    } else {
      result.push(vermelho)
    }
    return result
  }
    return (

        <>
        <Box sx={{width: 'auto'}}>
        <Card titulo={<CardTitulo titulo={props.titulo} icon={<CalculateOutlinedIcon/>}  help
        tooltip={<span>O gráfico mostra a porcentagem das marcações recebidas por cada opção de resposta (a, b, c, d, e). <br/><br/>
          A barra verde representa a opção da resposta correta.<br/><br/>
          Útil para verificar os erros cometidos pelos alunos.<br/><br/>
          Dica 1: passe o mouse sobre a barra do gráfico que representa a opção de resposta para revelar o texto da opção.<br/><br/>
          Dica 2: clique na seta que aponta para baixo para revelar o texto do enunciado da questão.
          
          </span>}
        
        /> }  margin_top={20} fontSizeTitulo="1.2rem" divider>

            <Box sx={{mt:0}}>
            <Grid container sx={{mb:-2}}>

            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} sx={{border: 'none'}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{color: 'text.secondary', width: '33%', flexShrink: 0 }}>
            Enunciado
           
          </Typography>
          
        </AccordionSummary>  
        <AccordionDetails>

            <Enunciado enunciado={props.questao.enunciado} />
            </AccordionDetails>
            </Accordion>
            </Grid>
            
            {props.questao.tipo === 1 &&
            
            <BarChart 
              dataset={props.dataset}
              yAxis={[{categoryGapRatio: 0.3, scaleType: 'band', dataKey: 'y',  

                label: 'Opções de resposta',
                colorMap: {
                  type: 'ordinal',
                  values: ['a)', 'b)', 'c)', 'd)', 'e)'],
                  colors: Cor()
                },
                valueFormatter: (x, context) =>
                  context.location === 'tick'
                    ? `${x}`
                    : `Opção ${x} - ${props.dataset[LetraIndex(x)].x}% de marcações`  
              ,
              
              }]}
              series={[{ dataKey: 'x', ry: 5, valueFormatter: (x, { dataIndex }) => {
                   
                
                  return (
                    <Grid container sx={{maxWidth: 500}}>
                    <Enunciado enunciado = {props.questao.opcoes_texto[dataIndex]} />
                    </Grid>
                   
                  )
                  
                  
                },

                }]}
              layout="horizontal"
              bottomAxis={{ disableTicks: true, label: 'Porcentagem %' }}
              leftAxis={{ disableTicks: true }}
              tooltip={{trigger: 'axis'}}
              grid={{ vertical: true }}
              borderRadius={7}
              barLabel={(item, context) => {
                if (item.value > 2) {
                return item.value + '%'
                }
              }}
              sx={{ pl:1, mt:-1,
                [`& .${barLabelClasses.root}`]: {
                  fill: 'white',
                  
                },
              }} 
                                                    
              {...chartSetting}
            />
            }   

            {props.questao.tipo !== 1 &&

            <Typography variant='body2'  mt={4} ml={2} mr={2}>
              Tipo de questão não suportado no gráfico.
            </Typography>
            }

            </Box> 
        </Card>
        </Box>
        </>
    )
}   

const chartSetting = {
    
    
  
  height: 350,
};

function LetraIndex (letra) {

  switch (letra) {
      case 'a)': return 0;
      case 'b)': return 1;
      case 'c)': return 2;
      case 'd)': return 3;
      case 'e)': return 4;
}
}

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: 'none',
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));