import * as React from 'react'
import { Slide, Avatar, Grid, Box, Typography, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, useTheme, Chip}  from '@mui/material';

import AccordionCustom from '../../views/components/view.accordion';
import CardTitulo from '../../views/components/view.card.titulo';

import AssignmentIcon from '@mui/icons-material/Assignment'

export default function ListaStatsAlunosRespostas (props) {
  
  const { alunos, questoes } = props;
  console.log (alunos)
  const num = React.useRef (0);

  function AddNum () {
    num.current++;
    console.log (num.current)
    return num.current
  }

  return (
    <Box  sx={{width: 'auto'}} >
        <AccordionCustom titulo={<CardTitulo icon={<AssignmentIcon  />} titulo = 'Respostas' />} variant={'body1'} fontSizeTitulo={'1.2rem'} nome='Respostas' borderRadius={18} divider={true} key="Respostas"componentes = {<>   
    
          <Legenda />
    <TableContainer component={Paper} sx={{mt:4, mb: 2, boxShadow: 'none'}}>
      <Table aria-label="collapsible table" size="small" sx={{ '& > *': { borderBottom: 'unset' }}}>
        
        <TableHeadCustom num={questoes}/>
        
        <TableBody>
        <TableRow>
        <TableCell align="left" colSpan={2}  ></TableCell>
       
         <TableCell align="left"   >Questão</TableCell>
         </TableRow>
          {
          alunos.map((row, index) => (
            <Row key={row.nome+row.sobrenome} row={row} num ={index} questoes={questoes} />
          ))
         
         }
        </TableBody>
      </Table>
    </TableContainer>
    
    </>} />
    </Box>
  );
}

 

function Row (props) {
  const { row,  num, questoes } = props;
  
  const [open, setOpen] = React.useState(false);
  const numQuestao = React.useRef (0); 
  const [questoesTotal] = React.useState(questoes.length);

  function SemResposta () {
    
    const [array] = React.useState (Array());

    function Array () {
      let array = []
      for (let i = 0; i < questoesTotal; i++) {
        array.push ('')
      }
      return array
    }
  
    return (
      <>
      {array.map ((row, index) => (
      <Grid item>
              
              <Avatar key={row.sobrenome+row.nome} sx={{ bgcolor: questoes [index].tipo !== 1 ? 'cinza.light' : 'info.main', width: 34, height: 34, fontSize: 12, fontWeight: '600', color: 'cinza.light'}} variant="rounded">
            
                { questoes [index].tipo !== 1 ? (index+1)+'Z' : (index+1)+'X'  }
            
              </Avatar>
            
            </Grid>
  ))}
      </>
    )
  }

  return (
    
    <React.Fragment>
      <Slide in={true} direction='down' timeout={1000} ease  >
      <TableRow sx={{ '& > *': { borderBottom: 'none', pb:-1, pt:1}  }} hover selected={open}>
      
      {/* NÙMERO DA QUESTAO */}
      <TableCell sx={{py:0}}  align="left">
        
        <Chip label={num+1} variant='outlined' size='small' sx={{color: 'primary.main', fontWeight: '500', ml:0, mb:1, mt:1}} />
      
      </TableCell>      

      {/* NOME DO ALUNO */}  
      <TableCell sx={{py:0}}  align="left">{row.nome + ' ' + row.sobrenome} </TableCell>

      {/* RESPOSTAS */}  
      <TableRow sx={{ '& > *': { borderBottom: 'unset', pb:-1, pt:1}}}>
        <TableCell  align="left">{
           <Grid container spacing={1}>
            
            {row.respostas.length === 0 &&
              <SemResposta />
            }
            {row.respostas.map ((row, index) => (
              
              <Grid item>
              
                <Avatar key={row.sobrenome+row.nome} sx={{ bgcolor: Cor (row, questoes, index), width: 34, height: 34, fontSize: 12, fontWeight: '600', color: 'cinza.light'}} variant="rounded">
              
                  {(index+1)+row}
              
                </Avatar>
              
              </Grid>
        ))}
        </Grid>
      
        }</TableCell>
        </TableRow>
                
      </TableRow>
      </Slide>
    </React.Fragment>
  );
}

function TableHeadCustom (props) {

      return (
        <>
        <TableHead >
           
        <TableRow>
          
          <TableCell  align="left">Num</TableCell>
          <TableCell  align="left">Nome</TableCell>
          <TableCell align="left">Respostas</TableCell>
        
        </TableRow>
      
     </TableHead>
        </>
      )
    }

 
function Cor (aluno, questoes, numQuestao) {
      
       console.log (questoes)
      if (questoes [numQuestao].tipo !== 1) return 'cinza.light'
      
      if (aluno === 'X' || aluno === undefined) {
             return 'info.main'
      }

      if (aluno === 'Z' || aluno === undefined) {
        return 'chart.amarelo'
 }
      
      if (aluno === questoes [numQuestao].gabarito_objetiva) {
             return 'chart.verde'
      } else {
        return 'chart.vermelho'
      }
      
  }
  
  function Legenda () {

    return (<>
    
    <Stack mt={3} spacing={4} direction={'row'}>
    
    <Stack direction={'row'} alignItems={'center'} spacing={1}>
    <Avatar sx={{ bgcolor: 'chart.verde', width: 12, height: 12}} variant="rounded" >
    <Typography color='chart.verde'>
      1
    </Typography>
    
    </Avatar>

    <Typography fontSize={13}>
      resposta correta
    </Typography>
         
         
    </Stack>
    
    <Stack direction={'row'} alignItems={'center'} spacing={1}>
    <Avatar sx={{ bgcolor: 'chart.vermelho', width: 12, height: 12}} variant="rounded" >
    <Typography color='chart.vermelho'>
      1
    </Typography>
    
    </Avatar>

    <Typography fontSize={13}>
      resposta errada
    </Typography>
         
         
    </Stack>




    <Stack direction={'row'} alignItems={'center'} spacing={1}>
    <Avatar sx={{ bgcolor: 'info.main', width: 12, height: 12}} variant="rounded" >
    <Typography color='info.main'>
      1
    </Typography>
    
    </Avatar>

    <Typography fontSize={13}>
      aluno não respondeu
    </Typography>
         
         
    </Stack>

    <Stack direction={'row'} alignItems={'center'} spacing={1}>
    <Avatar sx={{ bgcolor: 'cinza.light', width: 12, height: 12}} variant="rounded" >
    <Typography color='cinza.light'>
      
          </Typography>
    
    </Avatar>

    <Typography fontSize={13}>
      não é questão objetiva
    </Typography>
         
         
    </Stack>

    





    </Stack>
    
    </>)
  }