import { useState, useEffect } from "react";
import {Grid, Typography} from '@mui/material';
import ModalSucessoErro from "../../../components/ModalSucessoErro/ModalSucessoErro";
import * as Data from '../../../services/service.data'
import { useLocalStorage } from "../../../services/service.hooks";

 
export default function MsgIniciarLista (props) {
    
    const success = false
    const errorTipo = 'info'
    
        
    const data = Data.Extrair (props.resultado.lista_termino)
    const label = props.resultado.nem_comecou ? 'INICIAR' : 'CONTINUAR'
    
    const [liberado, setLiberado] = useState (false)
    
    const componente = <>
      <Grid container display="flex" flexDirection={"column"} alignItems="center" justifyContent="center">

      <Grid item xs={12} mt={-2} >
                       <Typography variant='body2' fontSize={25}>
                        {props.resultado.nem_comecou ? 'Vamos começar!' : 'Vamos continuar'}
                        </Typography>
                    </Grid>

      <Grid item xs={12} mt={2} >
                       <Typography variant='body2' fontSize={16}>
                        Fique atento para o prazo de entrega da lista que será no dia
                        </Typography>
                    </Grid>

                    <Grid item xs={12} mt={2} >
                       <Typography variant='h6' fontSize={20} color={'primary'}>
                        {data}
                        </Typography>
                    </Grid>
      </Grid>
    </>
    return (<>
        
        <ModalSucessoErro open ={true} success ={success} errorTipo={errorTipo} 
        data = {{btn1: {mostrar: true, disabled: false, color: 'primary', label: label},
        btn2: {mostrar: false, color: 'secondary', label: 'ACESSAR'},
        success: success, errorTipo: errorTipo, componente: componente
            }} 
        onClickBtn1 = {() => props.onClose()}

        ></ModalSucessoErro>
        
        </>
        );
}