import { useState } from 'react';
import { Stack, Grid, CircularProgress, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, InputLabel, Select, MenuItem, Divider, TextField, Typography } from '@mui/material';
import SnackbarAviso from '../../../../components/SnackBar/SnackBarAviso';
import { chamarApi } from '../../../../services/service.api';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';

function ReportarProblemaModal (props) {
    
    const [open, setOpen] = useState(true);
    const [loading, setLoading] = useState({inicio: false, fim: false, isSuccess: false});
    const [problema, setProblema] = useState('');
    const [dadosMsg, setDadosMsg] = useState({ msg: '' });
    const [botaoLigado, setBotaoLigado] = useState(false);
    const id_usuario = JSON.parse(localStorage.getItem ('usuario')).id;
    
    const handleChange = (event) => {
        setProblema(event.target.value);
      };
    
    
    function handleEnviar (e) {
        e.preventDefault ();
        setLoading ({inicio: true, fim: false, isSuccess: false});
        let data = {id_usuario : id_usuario, id_questao: props.questao, tipo: problema, texto: dadosMsg.msg}
        const getResult = async () => {
          const result = await chamarApi ({type: 'put', endpoint:'questoesReportarProblema', data: data}) // chama a API
          setLoading (result);
    
          if (result.isSuccess) {
            
            
          } 
          }  
          
          getResult();
        
        
    }

    function AtualizaCampos(e) {
        setDadosMsg({ msg: e.target.value });
        if (e.target.value.trim() && problema !== '') {
            setBotaoLigado(true);
        } else {
            setBotaoLigado(false);
        }
    }

    return (
        <Dialog open={open} onClose={props.onClose}>
            <DialogTitle >
            <Stack direction="row" spacing={1} alignItems='center'>
          <ReportProblemOutlinedIcon />
          <span>Reportar problema
          </span>
          </Stack>
          <Divider sx={{mt:1}} />       
           
            </DialogTitle>
         
        <DialogContent >
          <DialogContentText sx={{mt:2}}>
            Você receberá 10 créditos de bonificação caso o erro seja confirmado. Preencha as informações abaixo.
          </DialogContentText>
          
          {/* Tipo de problema combobox */}
          <Box sx={{ minWidth: 250, mt: 4}}>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Problema</InputLabel>
                <Select
                labelId="problema-select-label"
                id="problema-simple-select"
                value={problema}
                label="Problema"
                onChange={handleChange}
                >
                <MenuItem value={"Enunciado - faltam informações"}>Enunciado - faltam informações</MenuItem>
                <MenuItem value={"Enunciado - erro ortográfico"}>Enunciado - erro ortográfico</MenuItem>
                <MenuItem value={"Imagens faltando ou com erro"}>Imagens faltando ou com erro</MenuItem>
                <MenuItem value={"Gabarito Objetiva - resposta incorreta"}>Gabarito Objetiva - resposta incorreta</MenuItem>
                <MenuItem value={"Gabarito comentado - faltam informações"}>Gabarito comentado - faltam informações</MenuItem>
                <MenuItem value={"Gabarito comentado - erro ortográfico"}>Gabarito comentado - erro ortográfico</MenuItem>
                <MenuItem value={"Gabarito comentado - resolução"}>Gabarito comentado - resolução</MenuItem>
                <MenuItem value={"Outro"}>Outro</MenuItem>
                
                </Select>

                <TextField
                    sx = {{mt: 2}}
                    fullWidth
                    id="mensagem"
                    label="Descrição do erro"
                    variant="outlined"
                    value={dadosMsg.mensagem}
                    onChange={(e) => AtualizaCampos (e, setDadosMsg, setBotaoLigado)}
                    multiline
                    rows={4} />
            </FormControl>
         
        
         </Box>


        </DialogContent>
        
        <DialogActions>
          <Button color='secondary' onClick={props.onClose}>Voltar</Button>
          
          {loading.inicio && !loading.fim ? <Button disabled={true} ><CircularProgress size={20} /></Button>
          : <Button onClick={handleEnviar} disabled={!botaoLigado} >Enviar</Button>}
        </DialogActions>
      
      

       {/* Alerta de erro ou sucesso */} 
       <SnackbarAviso open={loading.fim} tipo={loading.isSuccess ? "success" : "error"} 
       msg={loading.isSuccess ? "Problema reportado com sucesso!" : "Houve um problema de comunicação com o servidor. Tente mais tarde."} onClose={(e) => props.onClose (e)}></SnackbarAviso>
      </Dialog>  
    )
} export default ReportarProblemaModal;