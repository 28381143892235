import { useState, useEffect } from 'react';
import { Box, Fade, Slide,  Typography, useTheme} from '@mui/material';
import { calcularNota } from './controller.nota';
import { Gauge,  gaugeClasses } from '@mui/x-charts';
import Card from '../../../../../views/components/view.card';
import CardTitulo from '../../../../../views/components/view.card.titulo';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import LogoAluno from '../../../Cadastro/view.aluno.logo';
import Akira from '../../../../../data/images/Akira_full2.png'
import AkiraVoice from '../../../../../data/sounds/AkiraVoice2.mp3'
import Tocar from '../../../Tocar/Tocar';

const easeOut = (t) => t * (2 - t); // Função de amortização


export default function Nota (props) {
    
    const nota = props.resultado.nota ? props.resultado.nota: calcularNota (props.resultado).pct;
    console.log (nota)
    const theme = useTheme (); 

   const [value, setValue] = useState(0); // Valor inicial para a animação
   

   useEffect(() => {
    let start = null;
    const duration = 8000; // Duração da animação em milissegundos

    const animate = (timestamp) => {
      if (!start) start = timestamp;
      const progress = timestamp - start;
      const val = easeOut(Math.min(progress / duration, 1)) * nota
      setValue(val.toFixed(1));

      if (progress < duration) {
        requestAnimationFrame(animate);
      }
    };

    requestAnimationFrame(animate);
  }, []);
    
   function Cor () {
    let valor = parseFloat(value)

    if ( valor >= 5 && valor < 7) return theme.palette.chart.amarelo
    if ( valor >= 7) return theme.palette.chart.verde
    return theme.palette.chart.vermelho
   }
     
    return (
        <>
        <Tocar src={AkiraVoice} play={props.play}/>
        
        <Box mr={-1} style={{position: 'relative',  overflowX: 'hidden'}} >
        
        
        
        <Box  sx={{
        border: '0.5px solid',        // Define a espessura e o estilo da moldura
        borderColor: 'primary.light', // Define a cor da moldura com base no tema
        borderRadius: '6px',       // Define o raio da borda para arredondar a moldura
        padding: '26px',            // Define o preenchimento interno do Box
        backgroundColor: 'background.paper',
        overflowY: 'hidden'       

      }}
    >
        <Box ml={-4} mt={1}>
        <LogoAluno />
        </Box>

        <Box mb={-4} sx={{position: 'relative', top: '7.5%', left: -120}}>
        <Fade in={true} timeout={1500}>
        <img style={{ width: '110%', height: 'auto', objectFit: 'contain', transform: 'scaleX(-1)' }} src={Akira} alt="Rin" />
        </Fade>
        </Box>

        
        
        
        <Typography fontSize = '1.1em' textAlign={'justify'} sx={{position: 'absolute', top:'88%', left:'5%', right:'5%' }} color= 'background.default' fontWeight={500}>
        <Slide in={true} timeout={1000} direction='right'>
            <Box sx={{backgroundColor: 'cinza.dark', px:2, py:1}} > 
         
           Akira: Estou calculando a sua nota...
        </Box>
        </Slide>
        </Typography>

        <Gauge  height={200}  value={value} valueMin={0} valueMax={10}  cornerRadius="50%" 
                   sx={(theme) => ({
                    [`& .${gaugeClasses.valueText}`]: {
                      
                      fontSize: '2.9em',
                      
                    },
                    
                    [`& .${gaugeClasses.valueArc}`]: {
                      fill: Cor()
                    },
                    [`& .${gaugeClasses.referenceArc}`]: {
                      fill: theme.palette.grey.light,
                    },
                    py:1, position: 'absolute', top:'25%', left:'25%', right:'5%'
                  })
                }
                  text={value.toString().replace('.', ',')}     
            />
       
        </Box>
        </Box>
 
        </>
    )
}

Nota.defaultProps = {
  play: false
}