import { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { saveAs } from 'file-saver';

import { QuestoesContext } from '../../../../services/service.questoes.provider';
import BaixarProvaView from './view.baixar.prova';
import { Editar as ModelEditarProva } from '../GerarProva/model.gerar.prova';
import { useTela } from '../../../../services/service.tela.provider';

export default function BaixarProva (props) {
  
  const [open, setOpen] = useState (true);
  const [baixado, setBaixado] = useState (false);  
  const navigate = useNavigate ();
  const { ZerarEscolhaQuestoes } = useContext (QuestoesContext)
  const [, setTela] = useTela ();

  console.count ('Baixar Prova')
  

  return (
  <>      
    
    <BaixarProvaView baixado={baixado} open = {open} onClose = {(e) => onClose(e, setOpen, navigate, ZerarEscolhaQuestoes, setTela, props.onClose)} handleBaixar = {(e) => handleBaixar(e, props, setBaixado)} />

  </>

);
}

function handleBaixar (e, props, setBaixado) {
  
  try {
    e.preventDefault ();
    const blob = new Blob([props.prova.data], { type: 'application/octet-stream' });
    saveAs (blob, props.prova.nome + '.docx');
    setBaixado (true);
  
  } catch {
    
    console.error ('Erro ao baixar a prova');
    return
    
  }

  SetProvaBaixadaComSucessoNoServidor (props);

}

const onClose = (e, setOpen, navigate, ZerarEscolhaQuestoes, setTela, onClose) => {
  e.preventDefault();
  setOpen(false);
  setTela ('Listas');
  
  ZerarEscolhaQuestoes();
  

  navigate ('/listas', {state: {tela: 'BaixarProva'}});

}

function SetProvaBaixadaComSucessoNoServidor (props) {
  
  let data = {id_usuario: props.usuario.id_usuario, id_lista: props.prova.id_lista, baixado: true}
  
  ModelEditarProva (data, async function (result) {
 
    if (result.isSuccess) {
      console.log ('Prova baixada com sucesso no servidor');

    } else {
      console.log ('Erro ao baixar a prova no servidor: ', result.erro +'-'+ result.msg);

      
    }

  })
}

