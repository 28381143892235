import { useContext } from "react";
import { VariaveisContext } from "../../services/service.variaveis.provider";
import { QuestoesContext } from "../../services/service.questoes.provider";
import { COPYRIGHT_NOME } from '../../data/config/config';

import { Box, Divider } from "@mui/material";

import Letra from "../../views/components/view.letra"; 

function Titulo (props) {
    const { isMobile } = useContext (VariaveisContext);
    const { usuario } = useContext (QuestoesContext);

    if (isMobile) return (
        <>
        <Letra texto="Olá, professor(a)" variant="h5" />
        <Letra texto={usuario.nome + ' ' + usuario.sobrenome + '!'} variant="h5" color='text.secondary'/>
        <Box sx={{mt: 1.5, mb: 1}}>
                <Letra texto={"Seja bem-vindo (a) ao Banco de Questões do " + COPYRIGHT_NOME} variant="body1" />
        </Box>
        
        </>
        
  );

    if (!isMobile) return (
        <>
            <Letra texto={"Olá, professor(a)" + ' ' + usuario.nome + ' ' + usuario.sobrenome + '!'} color='text.secondary' variant="h5" />
            
            <Box sx={{mt: 0.5}}>
                <Letra texto={"Seja bem-vindo (a) ao Banco de Questões do " + COPYRIGHT_NOME} variant="subtitle1" fontColor='text.secondary'/>
            </Box>
           
          
        
        </>
);

} export default Titulo;