import { IconButton } from "@mui/material"
import MenuIcon from '@mui/icons-material/Menu';

export const BtnMenu = ({handleDrawerOpen, isMobile, open}) => {return (
    <IconButton
    color="inherit"
    aria-label="open drawer"
    onClick={handleDrawerOpen}
    edge="start"
    
    sx={{
      
      ...(isMobile && {marginLeft: -1}),
      ...(!isMobile && {marginRight: 5}),
      ...(!isMobile && {marginLeft: -2}),
      ...(!open && { display: 'inline' }),
     
      ...(open && { display: 'none' }),
    }}
  >
    <MenuIcon />
  </IconButton>
  )}