import { Grid, Link } from '@mui/material';

export default function AlterarParaCriarConta ({isLoading, alterarParaCriarConta}) {

    return (
    <>
    
    <Grid item>
    
          <Link href="#" variant="body2" onClick={(e) => alterarParaCriarConta(e)}>
        
              {isLoading ? null : "Clique para criar sua conta!"}
        
          </Link>
    
    </Grid>

    </>
    )
}