export function customization (theme) {
    
    return {
    visual: {
        
        defaultPaymentOption: {
        
            bankTransferForm: true,
    
        },
      

        style : {
    
            theme: theme.palette.mode==='dark' ? 'dark' : 'default',
    
        }
    },
     
    paymentMethods: {
        
        bankTransfer: 'all',
        creditCard: 'all',

    },
}
}

export function initialization (props) {
    return {
        
        payer: {       
            
            email: props.email,
        },
      
        amount: props.dados.transaction_amount,
   }
}

export const onError = async (error) => {
       
    // callback chamado para todos os casos de erro do Brick
    console.debug (error)

};
  
   
export function onReady (e, setLoading) {
    
    console.debug (e)
    setLoading ({inicio: false, fim: false})

};