import { chamarApi } from '../../services/service.api';
import * as React from 'react';
import { OnlineStatus, useLocalStorage } from '../../services/service.hooks'

export function useListas (params, fetch, setLoading, location) {
    
    const [shouldFetch, setShouldFetch] = React.useState (fetch);  
    const [data, setData] = React.useState (null);
    const loading = React.useRef ({});
    const [ listas, setListas ] = useLocalStorage ('listas')
        
    const onlineStatus = OnlineStatus();
    
    console.log ('listas', listas)    

    const fetchData = React.useCallback(async () => {
        
        if (location.state && location.state === 'novolink') {
           // console.log ('fetch', location)    
            setData (listas)
       //     loading.current = {inicio: false, fim:false, isSuccess: false, online: false}
         //  setLoading (loading.current) 
            
     //       return [listas];
        }
        
        if (fetch===true) {

        try {
            console.debug ('Listar disciplinas');
            
            
            const result = await chamarApi({ type: 'get', endpoint: 'listasListar', data: params });
            
            if (result.isSuccess) {
         
                setListas (result.response)
                setData (result.response);
                loading.current = {inicio: false, fim: true, isSuccess: true, online: true}

            } else {
                
                setListas ([]);
                setData ([]);
                loading.current = {inicio: false, fim: true, isSuccess: false, online: true, erro: result.erro, msg: result.msg}        

            }

            setLoading (loading.current) 

        } catch (error) {
        
            setData ([]);
            loading.current = {inicio: false, fim: true, isSuccess: false, online: true,  erro: error.erro, msg: error.msg};
        
            setLoading (loading.current) 

        } finally {

            setShouldFetch(false);
        
        }
    }
    }, []); // Dependências do useEffect
    
    
   

    React.useEffect(() => {
    
    if (onlineStatus === false) {
        
        loading.current = {inicio: false, fim: true, isSuccess: false, online: false, erro: null, msg: null};
        setLoading (loading.current)
        setShouldFetch(false);
        
        return 
    }
    
    console.log (onlineStatus)
    
    if (shouldFetch===true && onlineStatus  ) {
        
        fetchData ();

    } else {

        setData (listas)
    }


}, [shouldFetch]);
    
if (location.state && location.state.selected === 'novolink') {
    return [ listas ];}
    else {
        return [ data ]
    }
}
    
   
    
   