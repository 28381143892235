import { useState, useEffect, useContext } from 'react';
import { Box, Slide, Grow } from '@mui/material';

import SiteFramework from '../../components/site/view.site-framework.jsx'
import Loading from '../../components/Loading/view.loading2.jsx';

import Card from '../../views/components/view.card.jsx';
import CardTitulo from '../../views/components/view.card.titulo.jsx';
import Titulo from '../../views/components/view.titulo';
import DisciplinaCard from './view.card.jsx';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';

import { VariaveisContext } from '../../services/service.variaveis.provider.jsx';
import * as Disciplina from './controller.disciplina.js'
import { useDisciplinas } from './model.disciplina.js';
import { useLocalStorage } from '../../services/service.hooks.js';
 
export default function Disciplinas () {
  
  const [ , setDisciplinas ] = useLocalStorage ("disciplinas");
  const [ usuario ] = useLocalStorage ("usuario");

  const { isMobile } = useContext (VariaveisContext);
  const [loading, setLoading] = useState ({inicio: true, fim: false, isSuccess: false, online: true});
  const [ disciplinas ] = useDisciplinas ({params: { id_usuario: usuario.id_usuario }}, loading.inicio, setLoading)
  const checked = true;

  useEffect (() => {
    
    setDisciplinas (disciplinas)
    console.log (loading)
  
  }, [disciplinas]);
     
  
  return (
    <>
    <SiteFramework 
        children = {<>
        
        {/* ************** DISCIPLINAS ************** */}
          
          {/* ************** LOADING ************** */}
          <Loading local="conteudo" loading={loading} online={loading.online} tipo='imagem' msg={"Buscando as disciplinas..."} mostrarErroOnlineStatus/> 
                    
          {loading.online && loading.inicio===false && loading.fim===true && loading.isSuccess && 
          
          <>
            <Card titulo={<CardTitulo icon={<MenuBookOutlinedIcon />}  titulo = 'Disciplinas' slide/>}  margin_top={20} fontSizeTitulo="1.2rem" divider >
            
              
            {Disciplina.VerificaSegmento (disciplinas, 'Ensino Fundamental II') && ( 
                <>
                
                <Slide in={true} timeout={700} direction = 'right'>
                <Box>
                <Titulo texto='Ensino Fundamental - Anos Finais' fontWeight={500}  divider/>
                </Box>
                </Slide>

                <Box sx ={{display: 'flex', flexDirection: 'row', alignItems: 'center', ...isMobile && {justifyContent: 'center'}, ...!isMobile && {justifyContent: 'center'}, flexWrap: 'wrap', mt: 2, mb: 3, p:0 }} >
                {disciplinas.map ((disc, index) => {if (disc.segmento.nome === 'Ensino Fundamental II') {return <>
                      <Grow in ={checked} 
                       style={{ transitionDelay: (index*80).toString() + 'ms' }}
                       {...(checked ? { timeout: 1000 } : {})}
                      >
                      <Box>
                     <DisciplinaCard key={disc.id} nome={disc.nome} icon={Disciplina.RetornaIcon (disc.nome, disc.id_segmento)} idDisciplina={disc.id} idSegmento={disc.segmento.id} disciplinaAbreviacao={disc.abreviacao} segmentoAbreviacao={disc.segmento.abreviacao}
                                     questoes = {disc.questoes} cor={Disciplina.RetornaCores(disc.nome)}/>
                     </Box>
                     </Grow>
                   </>
                 }})}
                 </Box>
                 
                </>
                )}
                
           {Disciplina.VerificaSegmento (disciplinas, 'Ensino Médio') && ( 
                <>
                
                <Slide in={true} timeout={800} direction = 'right'>
                <Box>
                <Titulo texto='Ensino Médio' fontWeight={500} divider/>
                </Box>
                </Slide>
                
                <Box sx ={{display: 'flex', flexDirection: 'row', alignItems: 'center', ...isMobile && {justifyContent: 'center'}, ...!isMobile && {justifyContent: 'center'}, flexWrap: 'wrap', mt: 2, mb: 3,p:0 }} >
                {disciplinas.map ((disc, index) => {if (disc.segmento.nome === 'Ensino Médio') {return <>
                  <Grow in ={checked} 
                       style={{ transformOrigin: '0 0 0', transitionDelay: (index*80).toString() + 'ms' }}
                       {...(checked ? { timeout: 1000 } : {})}
                      >
                    <Box>
                    <DisciplinaCard key={index} nome={disc.nome} icon={Disciplina.RetornaIcon (disc.nome, disc.id_segmento)} idDisciplina={disc.id} idSegmento={disc.segmento.id} disciplinaAbreviacao={disc.abreviacao} segmentoAbreviacao={disc.segmento.abreviacao}
                                    questoes = {disc.questoes} cor={Disciplina.RetornaCores(disc.nome)}/>
                    </Box>
                    </Grow>
                    </>
                }})}
               
                </Box>
                </>
                )}
           
        </Card>
        </>}   
    </>} />
    
    </>
  );
}
