import { Avatar, Stack, Box, Divider, Grid, Typography, useTheme } from "@mui/material"
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import HtmlPainel from '../../../../components/HtmlPainel/view.html.panel';

export default function OpcaoRespostasGabarito (props) {
    
    const theme = useTheme ();
    console.log (props)
    const bgColor = theme.palette.background.default
    const lineColor = theme.palette.cinza.main
    const textColor = theme.palette.cinza.dark
    const checkColor = theme.palette.primary.main
    const closeColor = theme.palette.vermelho.dark
    //const textColor = theme.palette.primary.contrastText
    
    function tornarImagensResponsivas(html) {
        
        // Encontrar todas as tags img e modificar seus atributos
        return html.replace(/<img([^>]*)>/g, (match, capture) => {
    
        // Se a tag já contém um estilo, adicionamos os estilos de responsividade a ele
        if (capture.includes('style="')) {
        return match.replace(/style="/, 'style="max-width: 100%; height: auto; object-fit: contain; ');
      
    }
        // Se a tag não contém um estilo, adicionamos um novo atributo de estilo
        return `<img style="max-width: 100%; height: auto; object-fit: contain;"${capture}>`;

    });
  }
  
  function qualLetra (item) {
  
    if (item === 0) return 'A';
    if (item === 1) return 'B';
    if (item === 2) return 'C';
    if (item === 3) return 'D';
    if (item === 4) return 'E';

  }
 
  function qualCheckIcon () { 
   
    if (props.gabarito_liberado && props.resposta === props.gabarito_objetiva) {
      return <><CheckIcon /></>
    } else {
        return <><CloseIcon /></>
    }
  }

  function qualCheckIconGabarito () { 
   
    if (props.gabarito_liberado && props.resposta === props.gabarito_objetiva) {
      return <><CloseIcon /></>
    } else {
        return <><CheckIcon /></>
    }
  }

  function qualCorCheckIcon () { 
   
    if (props.gabarito_liberado && props.resposta === props.gabarito_objetiva) {
      return checkColor
    } else {
        return closeColor
    }
  }
 
  function Letra () {
    
       if ( (props.resposta === qualLetra (props.item)) || (props.gabarito_objetiva === qualLetra (props.item))  ) {

          if ( (props.resposta === qualLetra (props.item)) && (props.resposta === props.gabarito_objetiva) ) {
            
            return (
            <Avatar sx={{ width: 30, height: 30, bgcolor: checkColor }}>
            <CheckIcon />
            </Avatar>
            )
          }

          if ( (props.resposta === qualLetra (props.item)) && (props.resposta !== props.gabarito_objetiva) ) {
            
            return (
            <Avatar sx={{ width: 30, height: 30, bgcolor: closeColor }}>
            <CloseIcon />
            </Avatar>
            )
          }

          if ( (props.gabarito_objetiva === qualLetra (props.item)) && (props.resposta !== props.gabarito_objetiva) ) {
            
            return (
            <Avatar sx={{ width: 30, height: 30, bgcolor: checkColor }}>
            <CheckIcon />
            </Avatar>
            )
          }
        } else {
         return (
            <>
               <Avatar sx={{ width: 30, height: 30, bgcolor: bgColor,  color: textColor }}>
     {qualLetra (props.item)}
    </Avatar>
            </>
         )
       } 
           
      }  
           
      function Letra2 () {
    
        if ( (props.resposta === qualLetra (props.item))   ) {
 
         
             
             return (
             <Avatar sx={{ width: 30, height: 30, bgcolor: checkColor }}>
             <CheckIcon />
             </Avatar>
             )
         
 
         
         } else {
          return (
             <>
                <Avatar sx={{ width: 30, height: 30, bgcolor: bgColor,  color: textColor }}>
      {qualLetra (props.item)}
     </Avatar>
             </>
          )
        } 
            
       }

  

    
  

    return (
        <>
            <Box sx = {{p: 1, ml: 1, mr: 1, mb: -1, backgroundColor: bgColor,  border: '1px solid ' + lineColor,  borderRadius: '16px 16px 16px 16px'}} >
                
            <Stack  direction={ "row" } spacing={2} sx={{alignItems: "center", ml:1, mr:2}}  divider={<Divider orientation="vertical" flexItem />}>
           
                    
           
                        
                    
                
            {props.gabarito_liberado ? 
            Letra()
            :
            Letra2()
          }
                
                    


           
                
                    <Typography sx = {{textAlign: "justify"}} color = {textColor}>
                    
                        <HtmlPainel html = {tornarImagensResponsivas (props.texto)} />

                    </Typography>
                
           
            
           
            </Stack>
            </Box>
        </>
    )
}