export const marrom = {
    
    nome: 'Terra',
    
    palette: {
        
        mode: 'dark',
        
        primary: {
        
            main: '#d0cdcb',
            light: 'rgb(217,215,213)',
            dark: 'rgb(145,143,142)',
            contrastText: 'rgba(0,0,0,0.87)',
        
        },
        
        secondary: {
        
            main: '#af003d',
            light: 'rgb(191,51,99)',
            dark: 'rgb(122,0,42)',
            contrastText: 'rgba(0,0,0,0.87)',
        
        },
        
        vermelho:  {
        
            main: '#d21f3c',
            dark: '#910D09',
            contrastText: 'rgba(0,0,0,0.87)',
        
        },
        
        verde:  {
        
            main: '#0B6623',
            dark: '#0B6623',
            contrastText: 'rgba(0,0,0,0.87)',
        
        },
        
        sidebar: {
        
            dark: '#292929',
        
        },
        
        text: {
        
            primary: '#d0cdcb',
            secondary: 'rgba(255,255,255,0.8)',
        
        },
        
        background: {
        
            default: '#080808',
            paper: '#121212',
        
        },
        
        navbar: {
         
            main: '#080808'
            // main: (theme) => theme.palette.paper,
        },
        
        success: {
        
            main: '#22b77f',
            light: '#d8f6e8',
            dark: '#004b50',
            contrastText: 'rgba(0,0,0,0.87)',
        
        },
        
        info: {
        
            main: '#2dc7db',
            light: '#dbfafc',
            dark: '#003768',
            contrastText: 'rgba(0,0,0,0.87)',
        
        },
        
        warning: {
        
            main: '#f9b626',
            light: '#fff4d9',
            dark: '#7a4100',
            contrastText: 'rgba(0,0,0,0.87)',
        
        },
        
        error: {
        
            main: '#fb8e67',
            light: '#ffe9e1',
            dark: '#7a0916',
            contrastText: 'rgba(0,0,0,0.87)',
        
        },
        cinza: {
                
            main: 'rgba(0,0,0,0.20)',
            dark: 'rgba(0,0,0,0.70)',
            contrastText: 'rgba(0,0,0,0.87)',
        },

        chart: {
            verde: '#52b202',
            vermelho:  '#d21f3c',
            amarelo: '#f9b626',
            contrastText: 'rgba(0,0,0,0.87)',
        },
        
        divider: 'rgba(255,255,255,0.15)',
    },
}