function eQuestaoEscolhidas (q, questoesEscolhidas, questoes) {
    
    return questoesEscolhidas.some (item => item.id === questoes [q-1].id);

}

function MudaQuestao (num, atual, total, total_parcial, setLoading, props, setQuestoesControle, questoesControle, questoesEscolhidas, questoes, loading) {
  
  // PROXIMA
  if (atual <= total) {
    
    atual = atual + num;
             
    if (atual  > total_parcial) {
      
      setLoading ({...loading, inicio: true, fim: false, isSuccess: false})
      props.onBuscarQuestoes (true);
      
    } else {
    
        setQuestoesControle ({...questoesControle, atual: atual, escolhida: eQuestaoEscolhidas (atual, questoesEscolhidas, questoes)});
    
    }
}
} 

export function handleProximaQuestao (e, atual, total, total_parcial, setLoading, props, setQuestoesControle, questoesControle, questoesEscolhidas, questoes, loading) {
    
    e.preventDefault ();  
    MudaQuestao (1, atual, total, total_parcial, setLoading, props, setQuestoesControle, questoesControle, questoesEscolhidas, questoes, loading);
    e.stopPropagation ();

}

export function handleQuestaoAnterior (e, atual, total, total_parcial, setLoading, props, setQuestoesControle, questoesControle, questoesEscolhidas, questoes, loading) {
    
    e.preventDefault ();  
    MudaQuestao (-1, atual, total, total_parcial, setLoading, props, setQuestoesControle, questoesControle, questoesEscolhidas, questoes, loading);
    e.stopPropagation ();

}

export function handleSelecionar (e, questoesControle, usuario, setOpenModal, questoesEscolhidas, questoes, setQuestoesControle, setQuestoesEscolhidas, snackValores, setSnack, atual) {
    e.preventDefault ();

    if (questoesControle.numEscolhidas + 1 > usuario.numcreditos) {
    setOpenModal (true);
    return;

}

    let a = [...questoesEscolhidas];
    a.push (questoes [atual - 1]);
    setQuestoesEscolhidas (a);
    setQuestoesControle ({...questoesControle, escolhida: true, numEscolhidas: a.length});
    snackValores.current = {acao: 'selecionar', tipo: 'verde', msg: 'Questão selecionada'}
    setSnack (true);
    e.stopPropagation ();
    }

export function handleRemover (e, questoesEscolhidas, setQuestoesEscolhidas, setQuestoesControle, snackValores, setSnack, atual, questoes, questoesControle) {

    e.preventDefault ();
    
    let a = questoesEscolhidas.filter (item => item.id !== questoes [atual - 1].id);

    setQuestoesEscolhidas (a);
    setQuestoesControle ({...questoesControle, escolhida: false, numEscolhidas: a.length});
    snackValores.current = {acao: 'remover', tipo: 'vermelho', msg: 'Questão removida'}
    setSnack (true);
    e.stopPropagation ();
}

export function handleRemoverTodas (e, setQuestoesEscolhidas, setQuestoesControle, snackValores, setSnack, questoesControle) {
    
    e.preventDefault ();
    e.stopPropagation ();
    setQuestoesEscolhidas ([]);
    setQuestoesControle ({...questoesControle, escolhida: false, numEscolhidas: 0});
    snackValores.current = {acao: 'remover-todas', tipo: 'vermelho', msg: 'Todas as questões foram removidas'}
    setSnack (true);

}