import { useState, useEffect } from "react";
import { Slide, Box, Typography, useTheme } from "@mui/material"
import HtmlPainel from '../../../../components/HtmlPainel/view.html.panel';
import Titulo from "./view.titulo";

export default function Enunciado (props) {
    
    const theme = useTheme ();

    const bgColor = theme.palette.primary.main
    const textColor = theme.palette.primary.contrastText
    const chipColor = theme.palette.background.default
    const borderRadius = props.resultado ? '32px 32px 32px 32px' : '0px 0px 32px 32px'
    const mt = props.resultado ? 1 : 1
    const [fadeKey, setFadeKey] = useState(0);

    useEffect(() => {
        setFadeKey(prevKey => prevKey + 1);
      }, [props.enunciado]);

    function tornarImagensResponsivas(html) {
        
        // Encontrar todas as tags img e modificar seus atributos
        return html.replace(/<img([^>]*)>/g, (match, capture) => {
    
        // Se a tag já contém um estilo, adicionamos os estilos de responsividade a ele
        if (capture.includes('style="')) {
        return match.replace(/style="/, 'style="max-width: 100%; height: auto; object-fit: contain; ');
      
    }
        // Se a tag não contém um estilo, adicionamos um novo atributo de estilo
        return `<img style="max-width: 100%; height: auto; object-fit: contain;"${capture}>`;

    });
  } 

    return (
        <>
            <Slide key={fadeKey} in={true} timeout ={1000} direction='down' ease >
            <Box sx = {{p:2, px: 3, ml:1, mr: 1, mb: 1, mt: mt, backgroundColor: bgColor, borderRadius: borderRadius}} >
                <Titulo chipColor = {chipColor} color = {textColor} titulo = {props.atual + ' de ' + props.total} {...props}  />
                
                <Typography color = {textColor} sx = {{textAlign: "justify"}}>
                    
                    <HtmlPainel html = {tornarImagensResponsivas (props.enunciado)} />

                </Typography>
            
            </Box>
            </Slide>
        </>
    )
}