export const militar = {

    nome: 'Floresta',
    
    palette: {
        
        mode: 'dark',
        
        primary: {
        
            main: '#a8bdad',
            light: 'rgb(185,202,189)',
            dark: 'rgb(117,132,121)',
            contrastText: 'rgba(0,0,0,0.87)',
        
        },
        
        secondary: {
        
            main: '#405667',
            light: 'rgb(102,119,133)',
            dark: 'rgb(44,60,72)',
            contrastText: 'rgba(0,0,0,0.87)',
        },

        vermelho:  {

            main: '#d21f3c',
            dark: '#910D09',
            contrastText: 'rgba(0,0,0,0.87)',
        
        },
        
        verde:  {
           
            main: '#0B6623',
            dark: '#0B6623',
            contrastText: 'rgba(0,0,0,0.87)',

        },

        sidebar: {
            
            dark: '#0e1a0f',

        },

        background: {

            default: '#030c03',
            paper: '#0e1a0f',
        
        },
        
        navbar: {
            
            main: '#0e1a0f'

        },

        text: {
        
            secondary: 'rgba(255,255,255,0.8)',
            primary: '#dde6e2',

        },

        success: {
        
            main: '#22b77f',
            light: '#d8f6e8',
            dark: '#004b50',
            contrastText: 'rgba(0,0,0,0.87)',
        
        },
        
        info: {
        
            main: '#2dc7db',
            light: '#dbfafc',
            dark: '#003768',
            contrastText: 'rgba(0,0,0,0.87)',
        
        },
        
        warning: {
        
            main: '#f9b626',
            light: '#fff4d9',
            dark: '#7a4100',
            contrastText: 'rgba(0,0,0,0.87)',
        
        },
        
        error: {
        
            main: '#fb8e67',
            light: '#ffe9e1',
            dark: '#7a0916',
            contrastText: 'rgba(0,0,0,0.87)',
        
        },

        cinza: {
                
            main: 'rgba(0,0,0,0.20)',
            dark: 'rgba(0,0,0,0.70)',
            contrastText: 'rgba(0,0,0,0.87)',
        },

        chart: {
            verde: '#52b202',
            vermelho:  '#d21f3c',
            amarelo: '#f9b626',
            contrastText: 'rgba(0,0,0,0.87)',
        },
        
        divider: 'rgba(255,255,255,0.15)',

    },
    
    typography: {
        
        subtitle1: {
        
            fontSize: 17,
        
        },
    },
}