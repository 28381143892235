import { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Divider, Menu, ListItemIcon, MenuItem } from '@mui/material';

import { useTela } from '../../services/service.tela.provider';



export default function MenuUsuario(props) {
  
  const [selectedIndex, setSelectedIndex] = useState();
  const navigate = useNavigate();
  const [ tela, setTela] = useTela ();

  const handleMenuItemClick = (event, link, index) => {
    setSelectedIndex(index);
    setTela (props.options[index][0])
    props.setOpen (false);
    
    if (link==='/usuario/logout') {navigate (link, {state: {tela: window.location.pathname}})} 
    else {navigate (link)} 
  };

  const handleClose = () => {
    props.setOpen (false);
  };

  return (
    
      
      <Menu
        id="lock-menu"
        //anchorEl={props.anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorReference={'anchorPosition'}
        anchorPosition={{ top: props.top, left: props.left }}
        open={props.open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'lock-button',
          role: 'listbox',
        }}
      >
        {props.options.map(([nome, divisor, link, icon], index) => (
              <>
              <MenuItem
                key={nome}
                
                selected={tela.atual === nome}
                onClick={(event) => handleMenuItemClick(event, link, index)}
              >
                <ListItemIcon>
              
              {icon}
            
            </ListItemIcon>  
               {nome}
              </MenuItem>
              {divisor && <Divider />}
              </>
            ))}
      </Menu>
    
  );
}