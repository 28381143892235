import { Button, Box,  CircularProgress } from '@mui/material';

export default function Botao ({botaoLigado, isLoading, handle, palavra}) {

    return (
        <>
        <Box display='flex' direction='row' justifyContent='center'>
        <Button
        
        disableElevation
        
        variant="contained"
        disabled={!botaoLigado || isLoading}
        onClick={(event) => handle (event)}
        sx={{ mt: 3, mb: 2}}
        
        >
        
        {isLoading ? <CircularProgress color="primary" sx={{ padding: 1 }} /> : palavra}
        
        </Button>
        </Box>
        </>
    )
}