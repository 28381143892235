import { Avatar } from "@mui/material"

export default function AvatarCustom (props) {

    return (
        <>
        
        <Avatar sx={{ m: 1, bgcolor: props.bgcolor, mt: props.mt }}>
            
            {props.icon}
        
        </Avatar>
        
        </>
    )
}