import * as React from 'react'
import * as Data from '../../services/service.data';
import { dataAtual } from '../../services/service.data';
import { Grid, Chip, Button, Box, Typography, Collapse, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, useTheme, ButtonGroup, Tooltip, Divider}  from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useNavigate } from 'react-router-dom';
import LeaderboardOutlinedIcon from '@mui/icons-material/LeaderboardOutlined';
import EventBusyOutlinedIcon from '@mui/icons-material/EventBusyOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import {URLServidorPrincipal, URLSite} from '../../data/config/config'
import SnackbarAviso from '../../components/SnackBar/SnackBarAviso';
import { QuestoesContext } from '../../services/service.questoes.provider';
import ModalSucessoErro from '../../components/ModalSucessoErro/ModalSucessoErro';
import { useTela } from '../../services/service.tela.provider';
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { ChipBGColor, ChipColor, eBtnEncerrarAntesDisabled, Status, possuiCreditos } from './controller.lista.link';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import ModalSemCreditos from './view.lista.modal.sem.creditos';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';


export default function ListaCard (props) {
   
  const [listas] = React.useState (props.listas)
  const id_lista_atual = props.id_lista ? props.id_lista : -1;
  const { usuario } = React.useContext(QuestoesContext);
  const [listaNomeColunas] = React.useState (['Num', 'Nome', 'Questões', 'Data', 'Links', 'Ação']);
 console.log (listas)
  return (
    <Box sx={{mt:1}}>
    
    <TableContainer component={Paper} sx={{mt:2, mb: 2, boxShadow: 'none'}} >
      <Table aria-label="collapsible table" size="small" sx={{  borderBottom: 'unset' }}>
        <TableHead >
            
            {listaNomeColunas.map ((item) => (
              <TableCell align="left">{item}</TableCell>
            ))}
            
            <TableCell />
        </TableHead>
        
        <TableBody >
         
          {
            
          listas.map((row, index) => {
            let id_lista = row.id;
            let num_links = row.lista_links.length
            let questoes_total = row.questoes_total
            let temp = id_lista_atual
            let index_temp = index
            return (
            <Row key={row.nome} index={index_temp} row={row} onClickAcoes={(btn, id_link, turma, questoes_total) => props.onClick(btn, id_link, id_lista, turma, questoes_total)} id_lista_atual = {temp} usuario ={usuario} num_links={num_links} questoes_total={questoes_total} />
          )})
         
         }
        </TableBody>
      </Table>
    </TableContainer>
    
    </Box>
  );
}

function Row (props) {
  const { row, onClickAcoes, id_lista_atual, usuario, num_links, index } = props;
  const theme = useTheme()
  const [open, setOpen] = React.useState(id_lista_atual === row.id ? true : false);
  const [openSemCreditos, setOpenSemCreditos] = React.useState (false);
  const [dados] = React.useState ([row.nome, row.questoes_total, Data.Extrair(row.createdAt)])
  
  const navigate = useNavigate()
  const [, setTela] = useTela ();

  function onClick (e, num_links) {
    e.preventDefault();

    let a = possuiCreditos (usuario, row.questoes_total);
    if (a) {
      setTela ('NovoLink')
      navigate ('/listas/link', {state: {id_lista: row.id, num_links: num_links, questoes_total: row.questoes_total}})
    } else {
      setOpenSemCreditos (true);
    }
}
  

  function handleComprarCreditos () {
    setTela ('Créditos')
    navigate ('/creditos', {state: {id_lista: row.id}})
  }    
  
  return (
    <>
    {openSemCreditos && <ModalSemCreditos open={openSemCreditos} questoes_total = {row.questoes_total} creditos = {usuario.numcreditos} setOpen = {setOpenSemCreditos} handleComprarCreditos={() => handleComprarCreditos()} />}
    <React.Fragment>
   
      <TableRow sx={{ '& > *': { borderBottom: 'none', pb:-1, pt:1}  }} hover selected={open}>
        
      <TableCell sx={{py:0}}  align="left">
      <Chip label={index + 1} variant='outlined' size='small' sx={{color: 'primary.main', fontWeight: '500', ml:0, mb:1, mt:1}} />
      </TableCell>      
        
        {dados.map ((item) => (
          <TableCell sx={{py:0}} key={item} align="left"> {item} </TableCell>
        ))}
        
        <TableCell sx={{py:0}} align="left">
          {row.lista_links.length}
          
          <Tooltip title="Gerar um link desta lista para uma nova turma" arrow>
          <Button  disableElevation size='small' variant="contained" onClick={(event) => onClick (event, row.lista_links.length)} sx={{ py:0, ml:2 }}>Criar</Button>
          </Tooltip>
        </TableCell>

        <TableCell sx={{py:0}} align="left">
          
        <BotoesAcoesLista lista={row}/>
        
        </TableCell>
        
        <TableCell sx={{py:0}}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>


      </TableRow>
      
      {/* LINKS */}
      <TableRow sx={{ '& > *': { borderBottom: 'none', pb:-1, pt:1}  }} >
        <TableCell style={{ paddingBottom: 8, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1, ml:3, mr:3 }}>
              <LinksTableTitulo />
             
              <Table size="small" aria-label="purchases" sx={{mt:1, '& > *': { borderBottom: 'none', pb:-1, pt:1} }}>
                <LinksTableHead />

                <TableBody>
                  { 
                  
                  row.lista_links.length > 0 ?
                  row.lista_links.map ((dados, index) => (
                    
                    <TableRow key={dados.date} sx={{ '& > *': {  borderWidth: 0.5, borderColor: theme.palette.mode === 'light' ? 'cinza.light' : 'cinza.dark', pb:-1, pt:1, borderBottom: 'unset'}  }} hover>
                      
                      <TableCell align="left">
                        
                        <Chip label={index + 1} variant='outlined' size='small' sx={{color: 'primary.main', fontWeight: '500', ml:0, mb:0, mt:0}} />
                        
                      </TableCell>
                      
                      <TableCell align="left">
                        {dados.turma}
                      </TableCell>

                      <TableCell align="left">
                        {Data.Extrair(dados.inicio)}
                      </TableCell>

                      <TableCell align="left">
                        {Data.Extrair(dados.termino)}
                      </TableCell>
                      
                      <TableCell align="left">
                         
                          <Chip label={Status (dados)} sx={{color: ChipColor(),  backgroundColor: ChipBGColor (dados)}} size="small" />
                          
                         
                      </TableCell>

                      <TableCell align="left">
                        <BotaoCopiarLink dados={dados} />
                      </TableCell>

                      <TableCell align="left">
                       <Botoes dados={dados} questoes_total={row.questoes_total} onClick={(btn, id_link, questoes_total) => onClickAcoes(btn, id_link, dados.turma, questoes_total)}  />
                      </TableCell>
                    
                      
                    </TableRow>
                    
                  ))
                   :
                   <>
                   
                   <TableRow hover mt={2}>
                     <TableCell colSpan={6} align="center">Nenhum link gerado até o momento</TableCell>
                   </TableRow>
                   </>
                 }
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      </React.Fragment>
    </>
  );
}

function LinksTableTitulo () {
  return (
    <>
    <Grid container spacing = {1} direction = 'row' sx={{mt:0}}>
              <Grid item>
              <LinkOutlinedIcon size='medium' color='primary' />
              </Grid>
              <Grid item>
              <Typography variant="body2" fontSize={'1.0rem'} color='primary' fontWeight={600} gutterBottom >
                Links
              </Typography>
              </Grid>
              </Grid>
              <Divider />
    </>
  )

}

function LinksTableHead () {

  return (

    <>
    <React.Fragment>
    <TableHead>
                  <TableRow sx={{ '& > *': { borderBottom: 'unset', pb:-1, pt:1}  }} >
                    
                    <TableCellTooltip texto={'Num'}  /> 
                    <TableCell align="left">Turma</TableCell>
                    <TableCell align="left">Inicio</TableCell>
                    <TableCell align="left">Término</TableCell>
                    <TableCellTooltip texto={'Status'} tooltip={<span>Não iniciada: a data de hoje é menor que a data de início determinada por você. Os alunos não visualizam as questões ainda.
                      <br/><br/>Em andamento: o prazo para o término da lista determinada por você não se esgotou. Os alunos ainda podem enviar respostas.
                      <br/><br/>Encerrada: o prazo para o término da lista determinada por você se esgotou. Os alunos não podem mais enviar respostas.
                      <br/><br/>Encerrada antes: o prazo para o término da lista foi alterado por você e se esgotou. Os alunos não podem mais enviar respostas.
                    </span>}  /> 
                    <TableCellTooltip texto={'Link'} tooltip={<span>Este é o link que deve ser repassado para a turma.<br/><br/> 
                    Cada turma possui um link diferente. Dessa forma, você obtém resultados diferenciados para todas as turmas que possui.
                    </span>}  /> 
                    <TableCellTooltip texto={'Ação'} tooltip={<span>
                        <strong>a. Status da lista:</strong> 'Encerrada' ou 'Encerrada antes'<br/><br/>
                        <strong>1. Botão Resultado:</strong> mostra as estatísticas da lista após o encerramento.<br/><br/>
                        <strong>b. Status da lista:</strong> 'Em andamento'<br/><br/>
                        <strong>1. Botão Alunos inscritos:</strong> mostra o nome e a quantidade de questões completada por cada aluno.<br/><br/>
                        <strong>2. Botão Encerrar antes:</strong> permite que você encerre a lista antes do prazo inicialmente determinado.<br/><br/>
                        <strong>c. Status da lista:</strong> 'Não iniciada'<br/><br/>
                        <strong>3. Botão Deletar:</strong> deleta o link gerado. Os créditos consumidos são devolvidos.

                    </span>} />
                   
                  </TableRow>
                </TableHead>
                </React.Fragment>
    </>
  )
}

function TableCellTooltip (props) {
  return (
    
      
      <TableCell>
      <Grid container spacing = {0} display='flex' flexDirection = 'row'  justifyContent={'flex-start'} alignContent={'center'}>
      
     
        {props.texto}
            
      {props.tooltip &&
      <Grid item xs={1}  mt={0.3} ml={0.3}>
        <Tooltip title={props.tooltip} arrow style={{whiteSpace: 'pre-line'}}>
        
          <HelpOutlineOutlinedIcon sx={{ width:'0.6em', height:'0.6em'}}/>
          
          </Tooltip>
          </Grid>  
          
      }
      </Grid>
        </TableCell>
     
    
  )
}

function Botoes (props){

  const [status]                   = React.useState (Status (props.dados)) 
  const [btnEncerrarAntesDisabled] = React.useState (eBtnEncerrarAntesDisabled (status))
   

return(
<ButtonGroup variant="text" aria-label="Basic button group" size='small' disableElevation>
  
  {(status === 'Encerrada' || status === 'Encerrada antes') &&
  <Tooltip title="Resultado" arrow>
    <Button onClick={() => props.onClick ('stats', props.dados.id_link)}><LeaderboardOutlinedIcon/></Button>
  </Tooltip>
  }

{(status === 'Em andamento' || status === 'Não iniciada') &&
  <Tooltip title="Alunos inscritos" arrow>
    <Button onClick={() => props.onClick ('alunos', props.dados.id_link)} disabled={status === 'Não iniciada'}  ><MoreHorizOutlinedIcon /></Button>
  </Tooltip>
}

  <Tooltip title="Encerrar antes" arrow>
    <Button disabled = {btnEncerrarAntesDisabled} onClick={() => props.onClick ('encerrar', props.dados.id_link)}><EventBusyOutlinedIcon /></Button>
  </Tooltip>
  
  <Tooltip title="Deletar" arrow>
    <Button onClick={() => props.onClick ('deletar', props.dados.id_link, props.questoes_total)} disabled={status !== 'Não iniciada'}  ><DeleteOutlineOutlinedIcon /></Button>
  </Tooltip>  

</ButtonGroup>
)

}

function BotoesAcoesLista (props) {

const { setNomeLista, setIDLista } = React.useContext (QuestoesContext);
const navigate = useNavigate();
const [, setTela] = useTela();

function handleVerQuestoes (e) {
  e.preventDefault();
  
  setIDLista (props.lista.id)
  setNomeLista (props.lista.nome)
  setTela ('VerQuestoes')
  
  navigate ('/listas/questoes')
}
return(
<ButtonGroup variant="text" aria-label="Basic button group" size='small' disableElevation>
  
  
  <Tooltip title="Ver questões" arrow>
    <Button onClick={(e) => handleVerQuestoes(e)}><AssignmentOutlinedIcon/></Button>
  </Tooltip>
  
  {/*
  <Tooltip title="Baixar arquivo Word" arrow>
    <Button disabled={props.lista.baixado}   onClick={() => props.onClick ('alunos', props.dados.id_link)}   ><FileDownloadOutlinedIcon /></Button>
  </Tooltip>
*/}

</ButtonGroup>
)

}

function BotaoCopiarLink (props){
  
  const [open, setOpen] = React.useState (false);

  function copiar ()  {
    let link = URLSite() + '/aluno/' + props.dados.id_link + '/' + props.dados.token
    navigator.clipboard.writeText(link);
    setOpen(true)
  }
  
  
  
  return(
  
    <>
    <Tooltip title="Copiar link" arrow>
      <Button onClick={() => copiar()} size='small' ><ShareOutlinedIcon/></Button>
    </Tooltip> 
 
    {open && <SnackbarAviso open={open} msg='Link copiado com sucesso!' onClose = {setOpen} time={1500}/>}
    </>
   )
  }

  