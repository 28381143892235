import { Alert, AlertTitle } from "@mui/material"

export default function Erro (props) {
    
    const { loading } = props 
    
    return (
        <>
        
        {
        loading.fim && !loading.isSuccess &&
        
        <Alert severity="error" sx={{mt:1}} >
        
              <AlertTitle>
        
                 Erro ao gerar a prova!
        
              </AlertTitle>  
        
                Tente novamente mais tarde.
        </Alert>
      }
        </>
    )
}