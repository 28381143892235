import { chamarApi } from '../../services/service.api';

export function Editar (data, callback) {
    
    const getResult = async () => {
        
        try {
               
            const result = await chamarApi ({type: 'patch', endpoint: 'usuarioEditar', data: data}) // chama a API
            
            callback (result);
            
        } catch (error) {
            
            callback (error);
        } 
    }
        getResult(); 
}

