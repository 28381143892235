import { BarChart, barLabelClasses } from '@mui/x-charts';
import Card from '../../../views/components/view.card';
import { Box, Grid, Typography, useTheme } from '@mui/material';
import Enunciado from '../../../pages/Questoes/Questao/view.enunciado'
import CardTitulo from '../../../views/components/view.card.titulo';
import MilitaryTechOutlinedIcon from '@mui/icons-material/MilitaryTechOutlined';
import { useState } from 'react';

export default function BarChartPctAcertosPorAssunto (props) {
    
    const theme = useTheme();
    console.log (props.questoesStats.acertosPorTag)
    const [dataset] = useState (Dataset(props.questoesStats.acertosPorTag))
    

    return (

        <>
        <Box sx={{width: 'auto'}}>
        <Card titulo={<CardTitulo titulo='Acertos' icon={<MilitaryTechOutlinedIcon />} help
        tooltip={<span>O gráfico mostra a porcentagem de acertos para cada assunto da lista.<br/><br/>
          
          Obs: apenas as questões de múltipla escolha são consideradas.<br/><br/>
                    
          </span>}
        />}  margin_top={20} fontSizeTitulo="1.2rem" divider>

            <Box sx={{mt:-4}}>
            
            <Grid container spacing={2} display={'flex'} direction={'column'}>
              {props.questoesStats.acertosPorTag.map ((a, index) => {
                return (
                  <Grid item>
                  <Typography >
                     {'Assunto ' + (index + 1) + ': ' + a.tag}
                  </Typography>
                  </Grid>
                )
              })}
              
            </Grid>

            <BarChart 
              dataset={dataset}
              yAxis={[{categoryGapRatio: 0.4, scaleType: 'band', dataKey: 'y',  
                
                valueFormatter: (x, context) =>
                  context.location === 'tick'
                    ? `${x}`
                    : `${dataset[x-1].label}` 
              ,
              
                
              }]}
              series={[{ dataKey: 'x', ry: 5,  valueFormatter: (v, { dataIndex }) => {
                   
                
                  return ( <>
                  
                  {dataset[dataIndex].x + '% de acertos neste assunto'}
                  
                  </>
                  )
                },
                
                }]}
              layout="horizontal"
              bottomAxis={{ disableTicks: true, label: 'Porcentagem %' }}
              leftAxis={{ disableTicks: true, label: 'Assunto',
                  

               }}
              xAxis={[{
                colorMap: {
                  type: 'piecewise',
                  thresholds: [50, 70],
                  colors: [theme.palette.chart.vermelho, theme.palette.chart.amarelo, theme.palette.chart.verde],
                }
              }]}
              tooltip={{trigger: 'axis'}}
              grid={{ vertical: true }}
              
              borderRadius={7}
              
              barLabel={(item, context) => {
                let pct = dataset[item.dataIndex].x 
              if (pct > 0) {
                return pct + ' %'
              }
              return null
              }}
              sx={{[`& .${barLabelClasses.root}`]: {
                fill: 'white',
                
              },}}                                       
              {...chartSetting}
            />
                
            </Box> 
        </Card>
        </Box>
        </>
    )
}   

const chartSetting = {
    
  
 
  height: 400,
  
};

const valueFormatter = (value) => `${value}` + '%';

const Dataset = (stats) => {
  const theme = useTheme();
  let data = [];

  stats.map ((s, index) => {
        
    data.push ({label: s.tag, y: index + 1, x: parseInt(s.pct.toFixed (0))})
    
  })
  
  return data
 
}

function LetraIndex (letra) {

  switch (letra) {
      case 'A': return 0;
      case 'B': return 1;
      case 'C': return 2;
      case 'D': return 3;
      case 'E': return 4;
}
}
