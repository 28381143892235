import { useRef, useState } from "react";
import { useTheme, Grid, FormControl, InputLabel, Input, InputAdornment, Typography, IconButton} from '@mui/material'
import { useNavigate } from "react-router-dom";
import { Formatada, dataAtual } from "../../services/service.data";
import * as modelNovoLink from '../Listas//model.lista.link'
import Loading from '../../components/Loading/view.loading2.jsx';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SnackbarAviso from "../../components/SnackBar/SnackBarAviso";

export default function ControllerNovoLink (props) {
    
    const [loading, setLoading] = useState({inicio: true, fim: false, isSuccess: false});
    const link = useRef ('');
    const navigate = useNavigate ();

    if (props.chamarApi===true && loading.inicio===true) {
        
        let dados = {}
        dados.turma = props.turma
        dados.inicio = Formatada (props.inicio, false) 
        dados.termino = Formatada (props.termino, true) 
        dados.id_lista = props.id_lista
        dados.liberar_gabarito = props.gabarito.current
        dados.questoes_total = Math.floor (props.questoes_total/2)
        dados.id_usuario = props.id_usuario

        modelNovoLink.Gerar (dados, async function (result) {
            
            if (result.isSuccess) {
                
                link.current = result.response.link
                setLoading ({...loading, inicio: false, fim: true, isSuccess: true, online: true})
                                
            } else {
                
                setLoading ({...loading, inicio: false, fim: true, isSuccess: false})
                console.log ('Erro ao cadastrar novo link', result)
                props.setChamarApi (false)
                
            }

           
        })
        
    }

    function onCloseModal () {
       
       // props.setChamarApi (false)
        navigate ('/listas', {state: {selected: 'Listas'}}) 
    }


    return (
        <>
        
        {/* ************** LOADING ************** */}
        {props.chamarApi===true && (loading.inicio===true || loading.isSuccess===true) &&
            <Loading local="conteudo" loading={loading}  tipo='imagem' online={loading.online}
                    msg={"Criando o link..."} mostrarErroOnlineStatus tipoSucesso='modal' onClose={() => onCloseModal()}
                    componente={<ModalMsgLink link={link.current}  />}           
            />
        } 

        


        </>
    )

}

function ModalMsgLink (props) {
    
    const [snackOpen, setSnackOpen] = useState (false);

    function SelecionaLink (e) {

        e.preventDefault ()
        navigator.clipboard.writeText(props.link);
        setSnackOpen (true)
    }

    return (
        
        
        <>
        {/* ************** SNACK AVISO ************** */}
        {snackOpen &&
            <SnackbarAviso open={snackOpen} isAlert={true} msg={'Link copiado!'}  posicao={{vertical: "bottom", horizontal: "center"}} onClose={(valor) => setSnackOpen (valor)} time={3000} variant='filled' tipo='success'/>
        }    
        
        <Grid item xs={12} mt={-2} >
            <Typography variant='h6' fontSize={25} textAlign='center'>
                Link criado com sucesso!
            </Typography>
        </Grid>

        <Grid item xs={12} mt={2} >
        <Typography variant='body1' textAlign='center'>
                Esse é o link de acesso da lista que deve ser repassado para a turma. 
        </Typography>
        </Grid>

        <Grid item xs={12} mt={1} >
            <Grid container sx={{mt:3}} spacing={0} direction="row" justifyContent="center" alignItems="center">
            
            <Grid item >
                <FormControl   variant="outlined" size='small' fullWidth>
                    
                    
                    <Input
                        
                        id="standard-adornment-link"
                        type={'text'}
                        value={props.link}
                        endAdornment={
                        <InputAdornment position="end" sx={{ml:3}}>
                            <IconButton
                            aria-label="copiar link"
                            onClick={(e) => SelecionaLink(e)}
                            
                            >
                             <ContentCopyIcon fontSize="small" />
                            </IconButton>
                        </InputAdornment>
                        }
          />
          
                </FormControl>
            </Grid>
        
        

            </Grid>
        </Grid>    
        </>
    )
}

function ValidarDatas (dataInicio, dataTermino) {

    // Verifica se a data de início é anterior à data de término
    
    if (dataInicio.getTime() > dataTermino.getTime()) {
        
        return false
    } 

    return true;
    
    
}

export function ValidarFormulario (turma, dataInicio, dataTermino) {
    
    // Verifica se a data de início é válida
    if (turma !== '' && ValidarDatas(dataInicio, dataTermino)) {
        
        return true;
    
    }
    
    return false;
 
}

export function ChipStatus (props) {
    
    const data_atual = new Date (dataAtual());
    
    const theme = useTheme()
    console.log (theme)
    const data_inicio = new Date (props.inicio);
    
    const data_termino = new Date (props.termino)
    
    if (data_termino < data_atual) {
        //return 'terminou'
    
        return {label:'Não iniciada', backgroundColor: theme.palette.primary}
    } 
    
    if (data_atual < data_inicio) {
        //return 'ainda vai começar'
    
        return {label:'Não iniciada', backgroundColor: theme.palette.primary}
    }
    
    if (data_termino > data_atual && data_inicio <= data_atual) {
        //return 'em andamento'
        return {label:'Não iniciada', backgroundColor: theme.palette.primary}
    
    }
    
    }