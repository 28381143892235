export const light = {
    
    classico: {
    
        nome: 'Clássico',
        
        components: {
            MuiButton: {
                styleOverrides: {
                    root: {
                        boxShadow: 'none',
                        '&:hover': {
                            boxShadow: 'none',
                        },
                        '&:active': {
                            boxShadow: 'none',
                        },
                        '&:focus': {
                            boxShadow: 'none',
                        }
                    }
                }
            }
        },
        
        palette: {
            
            mode: 'light',
            
            primary: {
                
                main: '#546500',
                // main: '#137a17',
                light: 'rgb(66,148,69)',
                dark: 'rgb(13,85,16)',
            
            },
        
            secondary: {
                
                main: '#f0be98',
            
            },
            
            vermelho:  {
                
                main: '#d21f3c',
                dark: '#910D09',
                contrastText: 'rgba(0,0,0,0.87)',
            },
        
            verde:  {
                
                main: '#0B6623',
                dark: '#0B6623',
                contrastText: 'rgba(0,0,0,0.87)',
            
            },

            navbar : {
            
                main: '#137a17',
            
            },

            background: {
            
                // default: '#fffefc',
                default: '#f3f1e8',
                paper: '#fefcf4',
                transparent: 'rgba(243,241,232, 0.7)'
                        
                //default: 'rgba(66,148,69, 0.05)',
                //paper: 'rgba(66,148,69, 0.01)',
            
            },
            sidebar: {
            
                dark: '#0a3823',
        
            },
            
            text: {
            
                //primary: 'rgba(0,0,0,0.83)',
                primary: '#30312b'
            },
            
            success: {
                
                main: '#22b77f',
                light: '#d8f6e8',
                dark: '#004b50',
                contrastText: 'rgba(0,0,0,0.87)',

            },

            info: {

                main: '#2dc7db',
                light: '#dbfafc',
                dark: '#003768',
            },

            warning: {
                
                main: '#f9b626',
                light: '#fff4d9',
                dark: '#7a4100',
            },

            error: {

                main: '#fb8e67',
                light: '#ffe9e1',
                dark: '#7a0916',
            },

            cinza: {
                
                main: 'rgba(0,0,0,0.20)',
                dark: 'rgba(0,0,0,0.70)',
                contrastText: 'rgba(0,0,0,0.87)',
            },

            chart: {
                verde: '#52b202',
                vermelho:  '#d21f3c',
                amarelo: '#f9b626',
                contrastText: 'rgba(0,0,0,0.87)',
            }
        },  
    }
}