import { Box, IconButton, Typography, Stack } from "@mui/material"
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { styled } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import { useTela } from '../../services/service.tela.provider'
import { useNavigate } from 'react-router-dom';


    


export default function BotaoVoltar (props) {
    
    const [tela, setTela] = useTela ();
    const navigate = useNavigate ();

    const Btn = styled (IconButton) (({ theme }) => ({
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.mode === 'dark' ? grey[800] : grey[300],
        opacity: 1,
        
        '&:hover': {
        backgroundColor: theme.palette.mode === 'dark' ? grey[700] : grey[500],
        opacity: 1,
        
        
    },
      }));
    
    
    function onClick (e) {
        if (props.onClick) {
            props.onClick(e)
        } else
        {
            if (!props.naomudarnometela) {
                setTela (tela.anterior)
            }

            navigate (-1);
        }
    }  

    return (

        <>
        
        
            <Box mt={-3} mb={3}>
            
            <Stack direction="row" spacing={2} alignItems='center'>
            <Btn  variant="outlined" color='primary' size="medium" onClick = {(e) => {e.preventDefault();  onClick(e)}} >
            
            <NavigateBeforeIcon />
            
            </Btn>
            
            {props.titulo &&
            <Typography fontSize={props.fontSize} fontWeight={500}>
                    {props.titulo}
            </Typography>
            }
            
            </Stack>

            </Box>
        
        
        </>
    )
}

BotaoVoltar.defaultProps = {
    fontSize: '1.2em'
}