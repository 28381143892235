import { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import {Grid, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider} from '@mui/material'
import CheckCircleOutlineSharpIcon from '@mui/icons-material/CheckCircleOutlineSharp';

import { QuestoesContext } from '../../../../services/service.questoes.provider';
import { Editar as ModelEditarProva } from '../GerarProva/model.gerar.prova';
import { useTela } from '../../../../services/service.tela.provider';

export default function FinalizarGeracao (props) {
  
  const [open, setOpen] = useState (true);
  const [, setTela] = useTela();  
  const navigate = useNavigate ();
  const { ZerarEscolhaQuestoes } = useContext (QuestoesContext)
  
  console.count ('Baixar Prova')
  

  return (
  <>      
    
    <Dialog open={open} >
      
      <DialogTitle>
      
        Lista produzida com sucesso!
        <Divider /> 
      
      </DialogTitle>
      
      <DialogContent>
      
        <DialogContentText>
      
        <Grid container display="flex" flexDirection={"column"} alignItems="center" justifyContent="center">
      
                    <Grid item xs={12} >
      
                        <CheckCircleOutlineSharpIcon color="success" sx={{fontSize: "5em"}}></CheckCircleOutlineSharpIcon>
      
                    </Grid>
          
                    
                    <Grid item xs={12} mt={3} >
      
                        A lista está disponível na aba LISTAS. Crie o primeiro link e repasse para os alunos de uma turma.
      
                    </Grid>

        </Grid>  
        
        </DialogContentText>
        
        
    </DialogContent>
    
    <DialogActions>
                
        <Button onClick={(e) => onClose (e, navigate, ZerarEscolhaQuestoes, setTela, props)} >Fechar</Button>
        

    </DialogActions>
    
    </Dialog>

  </>

);
}


const onClose = (e, navigate, ZerarEscolhaQuestoes, setTela, props) => {
  e.preventDefault();
 
  setTela ('Listas')
  ZerarEscolhaQuestoes();
  SetProvaBaixadaComSucessoNoServidor (props);
  navigate ('/listas', {state: {tela: 'GerarProva'} });

}

function SetProvaBaixadaComSucessoNoServidor (props) {
  
  let data = {id_usuario: props.usuario.id_usuario, id_lista: props.prova.id_lista, baixado: false}
  
  ModelEditarProva (data, async function (result) {
 
    if (result.isSuccess) {
      console.log ('Prova baixada com sucesso no servidor');

    } else {
      console.log ('Erro ao baixar a prova no servidor: ', result.erro +'-'+ result.msg);

      
    }

  })
}