import { dataAtual } from '../../../services/service.data';
import * as modelAluno from './model.aluno.lista'

export function separarEnunciadoGabaritoObjetiva(questao) { 
    return questao.map(q => {
       
        if (q.tipo !== 1) {
            q.opcoes = [];
            return q;
        }
        
        if (q.tipo === 1) {
        const enunciadoCompleto = q.enunciado;
        const opcoes = [];
  
        // Expressões regulares para encontrar as opções
        const regexOpcoes = /<p>([a-e]\))/g;
        let match;
  
        // Encontrando todas as opções
        while ((match = regexOpcoes.exec(enunciadoCompleto)) !== null) {
          const inicio = match.index;
          const proximaOpcao = regexOpcoes.exec(enunciadoCompleto);
  
          const fim = proximaOpcao ? proximaOpcao.index : enunciadoCompleto.length;
          let   opcao = enunciadoCompleto.slice(inicio, fim).trim();
          
          // Removendo o texto 'a)', 'b)', 'c)', 'd)', 'e)'
          opcao = opcao.replace(/[a-e]\)/, '').trim();
          opcoes.push(opcao);
          
          // Resetando o regex para continuar a busca
          regexOpcoes.lastIndex = inicio + opcao.length;
        }
  
        // Atualizando o objeto da questão
        q.opcoes = opcoes;
        q.enunciado = enunciadoCompleto.split('<p>a)')[0].trim();
      }
      return q;
    });
  }

  function Letra (item) {
    
      
    if (item === 0) return 'A';
    if (item === 1) return 'B';
    if (item === 2) return 'C';
    if (item === 3) return 'D';
    if (item === 4) return 'E';
}
export function inserirMarcadoFalsoQuestoes (questao) {
 
    return questao.map ((a) => {
      let marcado = []

   
   a.opcoes.forEach ((o, index) => {
   if (a.tipo ===1 && a.resposta===Letra(index)) {
    marcado.push(true)
   } else
   {
    marcado.push(false)

   }
    })
    console.log (marcado)
    a.marcado = marcado
    return a;    
    })
}


export const getQuestoesValidas = (questoes) => {
    
    let total = 0;

    questoes.forEach ((q) => {

        if (q.tipo === 1) {
            total = total + 1
        }

    })
    
    return total;

}

export const getQuestoesRespondidas = (questoes) => {
    
    let total = 0;

    questoes.forEach ((q) => {

        if (q.tipo === 1 && q.resposta && q.resposta !== 'X') {

            total = total + 1
        
        }

    })
    
    return total;
    
}
export const containerStyle = {

    maxWidth: '800px',
    margin: '0 auto',
    position: 'relative',
    
   
};

  


export async function Encerrar (setLoading, aluno, navigate, callback) {
    
    let dados = {}
    dados.id_link = aluno.id_link
    dados.token_aluno = aluno.token_aluno
    dados.data = dataAtual();
    
    let respostas = ArrayRespostas (aluno);
    let tempos = ArrayTempos (aluno);
    
    dados.respostas = respostas;
    dados.tempos = tempos;
    console.log ('dados encerrar', dados)

    modelAluno.Encerrar (dados, async function (result) {
      
      if (result.isSuccess) {
        
        navigate ('/aluno/' + aluno.id_link + '/' + aluno.token, {state: 'resultado'});
        
      }

      callback (result);
      
  })
    
}  

function ArrayRespostas (aluno) {

    let result = []
    
    aluno.questoes.forEach (q => {
        
        if (q.tipo === 1) {

            if (q.resposta) {
                
                result.push (q.resposta)

            } else {

                result.push ('X')

            }
        } else {

            result.push ('Z')

        }
    });
    
    return result
};

function ArrayTempos (aluno) {

    let result = []
    
    aluno.questoes.forEach (q => {
        
        if (q.tipo === 1) {

            if (q.tempo) {
                
                result.push (q.tempo)

            } else {

                result.push ('0')

            }
        } else {

            result.push ('Z')

        }
    });
    
   
    return result
};