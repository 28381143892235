import { localStorageVariaveis } from "../../data/config/config";
import { Set } from "../../services/service.local-storage";
import * as modelUsuario from './model.usuario'

export function callApi (tipo, dadosLogin, dadosCriarConta, setLoading, setUsuario, navigate, setTela) {

    modelUsuario.LoginCadastrar (Body (tipo, dadosLogin, dadosCriarConta), Endpoint (tipo), async function (result) {

      setLoading (result);
      
      if (result.isSuccess) {
        
        //setUsuario (result.response);
        Set ('usuario', result.response, 'objeto')
        setTela ('Home')
        
        navigate ('/home', { state: { eLOGIN: true } });
        console.log (result.response)
      }
  })
    
} 

export function Body (tipo, dadosLogin, dadosCriarConta) {

    let data = {}
    
    if (tipo === 'login') {
      
      return data = {email: dadosLogin.email, senha: dadosLogin.senha}
    
    } else {
    
      return data = {nome: dadosCriarConta.nome, sobrenome: dadosCriarConta.sobrenome, email: dadosCriarConta.email, senha: dadosCriarConta.senha}
    
    }
}

export function Endpoint (tipo) {

    if (tipo === 'login') {
    
        return 'usuarioLogin'

    } else {
        
        return 'usuarioCadastrar'}

    }

export function ZerarVariaveisLocalStorage () {
    
    function ZeraUmaVariavel(nome, eJson, valor) {
    if (!localStorage.getItem(nome)) {
        if (eJson) {
            localStorage.setItem(nome, JSON.stringify(valor))
        } else {    
            localStorage.setItem(nome, valor);
    }
    }
    }
    localStorage.clear();
    
    localStorageVariaveis.map((item) => {
              ZeraUmaVariavel(item.nome, item.eJson, item.valor)
    })
}

// Cria armazenamento local para o objeto usuário
export function CriaLocalStorageUsuario () {
    if (!localStorage.getItem('usuario')) {
        localStorage.setItem('usuario', JSON.stringify({}));
      }
}

// Troca da tela de login para a tela de criar conta e vice-versa
// Zera os objetos dos campos de login e senha caso troque de login para criar conta. Isso serve para que os dados digitados na tela de login apareçam quando o usuário voltar 
// para a tela de login após ir para a tela de criar conta
export function AlteraEstado (e, estado, setEstado, setDadosLogin, setDadosCriarConta) {
    e.preventDefault();
    setEstado(estado);
    setDadosLogin({ email: '', senha: '' });
    setDadosCriarConta({ nome: '', sobrenome: '', email: '', senha: '', repetirSenha: '' });
}

// Retorna o objeto usuário do armazenamento local
export function RetornaLocalStorageUsuario () {
    return JSON.parse(localStorage.getItem('usuario'));
}

// Atualiza o objeto usuário do armazenamento local
export function AtualizaLocalStorageUsuario (usuario) {
    localStorage.setItem('usuario', JSON.stringify(usuario));
}   



