
import { Grid, Chip } from "@mui/material"

export default function Gabarito (props) {
    
    function GabaritoObjetiva () {
    
        if (props.tipo === 1) {
              if (props.gabarito_objetiva === "A" || props.gabarito_objetiva === "B" || props.gabarito_objetiva === "C" || props.gabarito_objetiva === "D" || props.gabarito_objetiva === "E") {
                  return "LETRA " + props.gabarito_objetiva;
              } else {
                  return "OPÇÃO " + props.gabarito_objetiva;
        }
          }
  
        if (props.tipo === 3) {
          return "Soma: " + props.gabarito_objetiva;
        }
  
        return props.gabarito_objetiva;
    }

    return <>
    
    {props.gabarito_objetiva &&
                                                
        <Grid item xs={12} >
        
            <Chip label={GabaritoObjetiva()} sx={{fontSize: 12, fontWeight: 400, ml:-0.5, mt:2}} />
        
        </Grid>
                        
    }
   
    </>
}