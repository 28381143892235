import { useState } from 'react';

import SiteFramework from '../../components/site/view.site-framework.jsx'
import { useLocalStorage } from '../../services/service.hooks.js';

import ModalSucessoErro from '../../components/ModalSucessoErro/ModalSucessoErro.jsx';
import Card from '../../views/components/view.card.jsx';
import CardTitulo from '../../views/components/view.card.titulo.jsx';
import Titulo from '../../views/components/view.titulo';
import FormularioUsuarioEditar from './view.formulario.usuario.editar';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Slide, Box } from '@mui/material';

export default function Configuracoes () {
  
  const [ usuario, setUsuario ] = useLocalStorage ("usuario");
    
  const [loading, setLoading] = useState({inicio: false, fim: false, isSuccess: false});
  const [dadosMsg, setDadosMsg] = useState('');
  const [open, setOpen] = useState (false);
  
  return (
    <>
    {/* ************** MODAL ************** */

    <ModalSucessoErro open={open} msg={dadosMsg} data={{errorTipo: "error", successTipo: "success", btn1: {label: "OK", color: "primary", component: null}, btn2: {mostrar: false}}}   
                      success={loading.isSuccess ? true : false} mudarPagina = {true} link = '/home' 
                            />
    }
    
    <SiteFramework 
        
        children = {<>
        
        {/* ************** CARD ************** */}
        
        <Slide in={true} timeout={700} direction='down'>
        <Box>
        <Card titulo={<CardTitulo icon={<EditOutlinedIcon />}  titulo = 'Editar' /> }  margin_top={20} fontSizeTitulo="1.2rem" divider > 
        <Titulo texto='Dados' divider/>
            
        {/* ************** FORMULÁRIO ************** */}  
        
        <FormularioUsuarioEditar loading={loading} setLoading = {setLoading} usuario = {usuario} setUsuario = {setUsuario} setDadosMsg = {setDadosMsg} setOpen = {setOpen} />
            
         </Card>
         </Box>
         </Slide> 
      </>} />
    
    </>
  );
}
