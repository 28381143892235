import { Grid, Button } from "@mui/material"

export default function BotaoGerarProva (props)  {
    
    const { disabled, onClick, isMobile } = props

    return (
    <>
        
    {!isMobile ? <>
        <Grid item>
        
        <Button variant="contained" size="small" onClick={(e) => onClick (e)}  disabled={disabled}>
        
            Gerar Prova
        
         </Button>
        
        </Grid> 
    </> : 
    <Button variant="contained" size="small" onClick={(e) => onClick (e)}  disabled={disabled}>
        
            Gerar
        
    </Button>
    
    }
    </>
    )
}


    