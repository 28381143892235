import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import CheckCircleOutlineSharpIcon from '@mui/icons-material/CheckCircleOutlineSharp';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import WarningAmberSharpIcon from '@mui/icons-material/WarningAmberSharp';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Dialog, DialogActions, DialogContent, DialogContentText, Button, Grid, Typography } from '@mui/material';

function ModalSucessoErro (props) {
    
    
    const { open, data, msg, success, successTipo, errorTipo, mudarPagina, link, titulo, backgroundColor } = props;
    const [openModal, setOpenModal] = useState(false);
    const navigate = useNavigate();
    const mostrarBtn1 = data.btn1.mostrar
   
    
    
    useEffect (() => {
        
        setOpenModal(open);

    }, [open])

    function Icone () {
        if (data.successTipo === "success" &&  success) {return <> <CheckCircleOutlineSharpIcon color="success" sx={{fontSize: "5em"}}></CheckCircleOutlineSharpIcon> </>}
        if (data.errorTipo   === "error"   && !success) {return <> <ErrorOutlineIcon            color="error"   sx={{fontSize: "5em"}}></ErrorOutlineIcon>            </>}
        if (data.errorTipo   === "warning" && !success) {return <> <WarningAmberSharpIcon       color="warning" sx={{fontSize: "5em"}}></WarningAmberSharpIcon>       </>}
        if (data.errorTipo   === "info"    && !success) {return <> <InfoOutlinedIcon            color="info"    sx={{fontSize: "5em"}}></InfoOutlinedIcon>            </>}
    }
    
    function handleBtn1Click (e) {
        
        e.preventDefault();
        
        if (mudarPagina) {
            
            if (success) {
                navigate (link);
            }
        }
        props.onClose(false);
        setOpenModal(false);
        
        
        
    }

    function handleBtn2Click (e) {
        e.preventDefault();
        props.onClickBtn2(e);
        setOpenModal(false);
        
    }
    return <>
     
    {openModal && (<Dialog open={openModal} aria-labelledby="form-dialog-title"
    
    >
        
        <DialogContent sx={{backgroundColor: props.backgroundColor}}>
            
            <DialogContentText sx={{textAlign: 'justify', mr:1}}>
                
                <Grid container display="flex" flexDirection={"column"} alignItems="center" justifyContent="center">
                    <Grid item xs={12} >
                        {Icone()}
                    </Grid>
                    
                    {titulo && titulo !== '' ?
                    <Grid item xs={12} mt={2} >
                    <Typography variant='h6' fontSize={25}>
                        {titulo}
                    </Typography>
                    </Grid> : null}
                    
                    {msg && msg !== '' ?
                    <Grid item xs={12} mt={2} >
                        {msg}
                    </Grid>
                    : null}
                    
                    {data.componente && (<Grid item xs={12} mt={props.mtComponente} mb={props.mbComponente} >
                        {data.componente}
                    </Grid>)}
                </Grid>
            
            </DialogContentText>
        </DialogContent>
        <DialogActions sx={{backgroundColor: props.backgroundColor}}>
            {mostrarBtn1===true ?
            
            <Button onClick={(e) => handleBtn1Click(e)} color={data.btn1.color} disabled={data.btn1.disabled ? data.btn1.disabled : false}>
            
                {data.btn1.label}
            
            </Button>
            : null
            
            }
            
            {data.btn2.mostrar ? (<Button onClick={(e) => handleBtn2Click (e)} color={data.btn2.color} disabled={data.btn2.disabled ? data.btn2.disabled : false}>
                {data.btn2.label}
            </Button>) : null}
        </DialogActions>
    </Dialog>
    )}
   </>

} 

export default ModalSucessoErro;


ModalSucessoErro.defaultProps = {
    open: false,
    data: {
        successTipo: "success",
        errorTipo: "error",
        btn1: {label: "OK", color: "primary"},
        btn2: {mostrar: false, label: "Cancelar", color: "secondary"},
        componente: null
    },
    msg: "",
    titulo: '',
    onClickBtn2: () => {},
    success: true,
    onClose: () => {},
    mudarPagina: false,
    link: '',
    backgroundColor: 'background.default',
    mtComponente: 3,
    mbComponente: null,
}