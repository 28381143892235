import { useContext } from "react";
import { keyframes, Fade, ListItem, ListItemButton, ListItemText, ListItemIcon, Divider } from "@mui/material";
import { styled } from '@mui/material/styles';
import { useNavigate, useLocation } from "react-router-dom";
import { VariaveisContext } from "../../services/service.variaveis.provider";

function MenuItem (props) {
    
    const {text, open, index, Icon, link, selected, divisor, isMobile, setOpen} = props;
    const {setOpenMenu} = useContext(VariaveisContext);

    const navigate = useNavigate();
    const location = useLocation();

    const handleClick = () => {
        props.onClick (text);
        
        let urlCompleta = `${location.pathname}`;
        urlCompleta = urlCompleta.substring(1);
        let texto = text;
        texto = texto.toLowerCase();
        
        
        if (texto===urlCompleta) return
        //   if (isMobile) {setOpen (false)};
        //setOpen (true);
        
        navigate(link, { state: { selected: text } });
    };
    
    const StyledListItemIcon = styled(ListItemIcon, {
        shouldForwardProp: (prop) => prop !== 'minhaCondicao',
      })(({ selected, theme }) => ({
        color: selected ? theme.palette.primary.contrastText : 'inherit',
      }));
   
      
      const myEffect = keyframes`
      0% {
        opacity: 0;
        
          
      }
      100% {
        
        opacity:1
      }
    `;
    

    return (
        <>    
        
        <ListItem key={text} disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                onClick={handleClick}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                  
                  backgroundColor: selected ? 'primary.main' : 'inherit',
                  color: selected ? 'primary.contrastText' : 'inherit',
                  '&:hover': selected ?  
                  {
                    backgroundColor: 'primary.main',
                   
                    color: 'primary.contrastText',
                  }
                  : {
                    backgroundColor: 'primary.main',
                    color: 'primary.contrastText',
                    },
                    animation: selected ? `${myEffect} 0.5s ease` : null, 
                   
                }}
              >
               
                <StyledListItemIcon selected = {selected}
                  sx={{
                    minWidth: 0,
                    mr: open ? 2 : 'auto',
                    justifyContent: 'center',
                    
                  }}
                  
                >
                
                  <Icon />
                </StyledListItemIcon>
                
                <ListItemText primary={text} primaryTypographyProps ={{fontSize: '0.9em', mt: 0.25}} sx={{ opacity: open ? 1 : 0,  }} />
              </ListItemButton>
            </ListItem>
           
            {divisor && <Divider  />}
            </>
    )
} export default MenuItem; 
