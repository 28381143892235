import * as React from 'react'
import { ToggleButton, ToggleButtonGroup, TextField, Slide, Divider, Avatar, Button, Box, Typography, Collapse, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, useTheme, Chip, Stack, Tooltip}  from '@mui/material';
import { exportar as exportarExcel } from '../../components/Excel/service.excel'
import AccordionCustom from '../../views/components/view.accordion';
import CardTitulo from '../../views/components/view.card.titulo';

import AssignmentIcon from '@mui/icons-material/Assignment'
import SnackbarAviso from '../../components/SnackBar/SnackBarAviso';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export default function ListaStatsAlunos (props) {
  
  const { turma } = props;
  const [alunos, setAlunos] = React.useState ([...props.alunos])
  const [erro, setErro] = React.useState (null)
  const [nota, setNota] = React.useState ('2.0')
  const [notaInicial, setNotaInicial] = React.useState ('10.0')
  const [limites, setLimites] = React.useState ([ ['0.0', '4.9'], ['5.0', '6.9'], ['7.0', '10.0'] ])

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = () => {
    setExpanded ((prev) => !prev);
  };

  function onClickUp () {
    
    if (nota === '10.0') return
    let a = parseFloat (nota)
    a +=  0.1
    setNota (a.toFixed(1))

  }

  function onClickDown () {
    
    if (nota === '0.1') return
    let a = parseFloat (nota)
    a -=  0.1
    setNota (a.toFixed(1))

  }

  function handleConverter () {
    
    let a = alunos.map (aluno => ({...aluno, nota: (parseFloat (aluno.nota) /  (parseFloat (notaInicial) / parseFloat (nota))).toFixed(1)  }))
    setAlunos (a)
    let b = ConverterLimites (limites, nota)
    setLimites (b);
    console.log (b)
    setNotaInicial (nota)
  } 


  return (<> 
     <Box sx={{width: 'auto'}} >
        
        <AccordionCustom titulo={<CardTitulo icon={<AssignmentIcon  />} titulo = 'Notas' />} variant={'body1'} fontSizeTitulo={'1.2rem'} nome='Notas' borderRadius={18} divider={true} key="Notas"componentes = {<>   

     {alunos.length > 0 && <>   
     <Legenda limites = {limites} />
     
     
     <Stack direction='row' justifyContent='start' alignItems='center' spacing = {2} divider={<Divider orientation="vertical" flexItem />} mt={3}>
     <Tooltip arrow title='Exporte as notas para uma planilha Excel'>
     <Button size='small' color='primary' variant='contained' onClick = {() => handleExpotartExcel (alunos, turma, setErro)} >
      Exportar
     </Button>
     </Tooltip>
     
     <Tooltip arrow title={<span>
      Converta a nota para outro valor.<br/><br/>

      Exemplo: você determinou que a resolução da lista pelos alunos vale até 2.0 pontos. Ao clicar no botão, um valor de 0.1 a 10 poderá ser inserido. <br/><br/>

      Neste exemplo, digite 2 e todas as notas apresentadas serão convertidas de 10 pontos para 2 pontos. <br/><br/>

      Isso facilita a sua vida!<br/><br/>

      Você também pode exportar essas notas para uma planilha Excel clicando no botão EXPORTAR ao lado!<br/><br/>

      Obs: a conversão das notas nesta tela é temporária e não afeta os dados das notas nas outras abas.<br/><br/>
     </span>}>
     <Button size='small' color='primary' variant='contained' onClick = {handleChange} >
      Converter nota
     </Button>
     </Tooltip>

     
     </Stack>
    
     <Collapse in={expanded} timeout={1000}>
     
     <Stack direction='row' justifyContent='start' alignItems='center' spacing = {1} mt={3}>
     
     <TextField
        size='small'
        id="outlined-number"
        label="Nota"
        
        InputLabelProps={{
          shrink: true,
        }}
        value={nota}
        defaultValue={2.0}
        helper={'Nota entre 0.0 e 9.9'}

      />

<Box mt={-1}>
<ToggleButtonGroup
  orientation="vertical"
  
  exclusive
  
>
  <ToggleButton value="list" aria-label="list" sx={{width: '18px', height:'18px'}} onChange={() => onClickUp()}>
    <KeyboardArrowUpIcon sx={{width: '14px', height:'14px'}}/>
  </ToggleButton>
  <ToggleButton value="module" aria-label="module" sx={{width: '18px', height:'18px'}} onChange={() => onClickDown ()}>
    <KeyboardArrowDownIcon sx={{width: '14px', height:'14px'}}/>
  </ToggleButton>
  </ToggleButtonGroup>
  </Box>
   
  <Button size='small' color='primary' variant='outlined' onClick = {handleConverter} >
      Converter
     </Button>    
   
   
        </Stack>

   
   </Collapse>
   </>}



    <TableContainer component={Paper} sx={{mt:4, mb: 2, boxShadow: 'none'}} >
      <Table aria-label="collapsible table" size="small" sx={{ '& > *': { borderBottom: 'unset' }}}>
        
        <TableHeadCustom />
        
        <TableBody>
         
          {
          alunos.map((row, index) => (
            <Row key={row.nome+row.sobrenome} row={row} num ={index} limites={limites}/>
          ))
         
         }
        </TableBody>
      </Table>
    </TableContainer>
    </>} />

    
    
    </Box>

{erro !== null &&

  <SnackbarAviso open = {erro===true || erro===false} tipo={erro===true ? 'error': 'success'} msg={erro===true ? 'Erro ao exportar o arquivo' : 'Arquivo exportado com sucesso!'} onClose={setErro}/>
  }
</>



  );
}

 

function Row (props) {
  const { row,  num, limites } = props;
  
  const [ open ] = React.useState(false);
  const [ chip ] = React.useState(Status (row));
   
  return (
    
    <React.Fragment>
      <Slide in={true} direction='down' timeout={1000} ease  >
      <TableRow sx={{ '& > *': { borderBottom: 'none', pb:-1, pt:1}  }} hover selected={open}>
      <TableCell sx={{py:0}}  align="left">
      <Chip label={num + 1} variant='outlined' size='small' sx={{color: 'primary.main', fontWeight: '500', ml:0, mb:1, mt:1}} />
       </TableCell>      
        <TableCell sx={{py:0}}  align="left">{row.nome + ' ' + row.sobrenome} </TableCell>
        
        <TableCell sx={{py:0}} align="left">{
         <Avatar sx={{ bgcolor: CorNota (row.nota, limites), width: 34, height: 34, fontSize: 12, fontWeight: '600', color: 'cinza.light'}} variant="rounded">
         {row.nota}
         </Avatar>
        }</TableCell>
        <TableCell sx={{py:0}} align="left">{<Chip label={chip.label} size='small' sx={{backgroundColor: chip.color, color: 'cinza.dark', fontWeight: '400', ml:0, mb:0.5}} />}</TableCell>
                
      </TableRow>
      </Slide>
    </React.Fragment>
    
  );
}

function TableHeadCustom () {

      return (
        <>
        <TableHead >
         
            
         
         <TableCell  align="left">Num</TableCell>
         <TableCell  align="left">Nome</TableCell>
         <TableCell align="left">Nota</TableCell>
         <TableCell align="left">Status</TableCell>
         
      
     </TableHead>
        </>
      )
    }

    function Status (aluno) {

      if (aluno.encerrada === true) {
             return {label: 'Entregue', color: 'success.light'
          }
      }
      if (aluno.encerrada === false && aluno.respostas !== null) {
              return {label: 'Iniciada'
      }
    }
      if (aluno.encerrada === false && aluno.respostas === null) {
          return {label: 'Não iniciada', color: 'error.light'
      }
    }
  }
  
  function CorNota (nota, limites) {

    nota = parseFloat (nota);

    if (nota < limites[1][0] ) return 'chart.vermelho'
    if (nota >= limites[1][0] && nota < limites[2][0] ) return 'chart.amarelo'
    if (nota >= limites[2][0] && nota <= limites[2][1] ) return 'chart.verde'
  }

  function Legenda ({limites}) {

    return (<>
    
    <Stack mt={3} spacing={4} direction={'row'}>
    
    <Stack direction={'row'} alignItems={'center'} spacing={1}>
    <Avatar sx={{ bgcolor: 'chart.verde', width: 12, height: 12}} variant="rounded" >
    <Typography color='chart.verde'>
      1
    </Typography>
    
    </Avatar>

    <Typography fontSize={13}>
      {'entre ' + limites[2][0] + ' e ' + limites[2][1]}
    </Typography>
         
         
    </Stack>

    <Stack direction={'row'} alignItems={'center'} spacing={1}>
    <Avatar sx={{ bgcolor: 'chart.amarelo', width: 12, height: 12}} variant="rounded" >
    <Typography color='chart.amarelo'>
      1
    </Typography>
    
    </Avatar>

    <Typography fontSize={13}>
    {'entre ' + limites[1][0] + ' e ' + limites[1][1]}
    </Typography>
         
         
    </Stack>

    <Stack direction={'row'} alignItems={'center'} spacing={1}>
    <Avatar sx={{ bgcolor: 'chart.vermelho', width: 12, height: 12}} variant="rounded" >
    <Typography color='chart.vermelho'>
      1
    </Typography>
    
    </Avatar>

    <Typography fontSize={13}>
    {'entre ' + limites[0][0] + ' e ' + limites[0][1]}
    </Typography>
         
         
    </Stack>





    </Stack>
    
    </>)
  }

  
  async function handleExpotartExcel (alunos, turma, setErro)  {
    try {
      
      let data = [];
      data = alunos.map ((aluno, index) => {
      
        return {
        'Número': index + 1,
        'Nome': aluno.nome + ' ' + aluno.sobrenome,
        'Nota': parseFloat (aluno.nota),
        'Status': Status (aluno).label,
        'Respostas': aluno.respostas.join(','),

      }
})
      
      const result = await exportarExcel (data, turma);
      console.debug (result);     
      setErro (false);

      // Você pode continuar com outras operações aqui
    } catch (error) {
    
      setErro (true);
    
    }
  };

  function ConverterLimites (limites, nova_nota, antiga_nota) {
   
    const pct1 = 0.49;
    const pct2 = 0.5;
    const pct3 = 0.69;
    const pct4 = 0.7;

    return limites.map ((limit, index) => {
      
      
      let novo;

      if (index === 0) {
        limit [0] = limit[0]
        novo = ((nova_nota * pct2) - 0.1).toFixed(1);
        limit [1] = novo;
        
      }

      if (index === 1) {
        novo = (nova_nota * pct2).toFixed(1);
        limit [0] = novo;
        novo = ((nova_nota * pct4) - 0.1).toFixed(1);
        limit [1] = novo;
      }

      if (index === 2) {
        novo = (nova_nota * pct4).toFixed(1);
        limit [0] = novo;
        limit [1] = nova_nota;
        
      }

    return limit
    })

  }
  
  