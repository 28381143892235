

import React, { useRef, useEffect } from 'react';

const Tocar = ({ src, play }) => {
  const audioRef = useRef(null);

  useEffect(() => {
    const handlePlay = () => {
      if (audioRef.current && play) {
        audioRef.current.play().catch(error => {
          console.error("Playback error:", error);
        });
      } else if (audioRef.current && !play) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0; // Reseta o áudio para o início
      }
    };

    if (audioRef.current) {
      audioRef.current.addEventListener('play', handlePlay);
    }

    return () => {
      if (audioRef.current) {
        audioRef.current.removeEventListener('play', handlePlay);
      }
    };
  }, [src, play]);

  useEffect(() => {
    if (play) {
      audioRef.current.play().catch(error => {
        console.error("Playback error:", error);
      });
    } else if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
  }, [play]);

  return (
    <div>
      <audio ref={audioRef} src={src} />
    </div>
  );
};

export default Tocar;
