import { Slide, Fade, Box } from '@mui/material';

import logo from '../../../data/images/logo_completo_dark.png';

export default function LogoAluno (props) {

    return (<>
    
        <Slide in={true} ease timeout={1000} direction="right">
        <Fade in={true} ease timeout={1000}>
        <Box ml={3} mt={-3}>
        <img style={{ width: '250px', height: 'auto', objectFit: 'contain', paddingTop: '0.1em', paddingBottom: '0.1em', margin:'auto' }} src={logo} alt="Logo" />
        </Box>
        </Fade>
        </Slide>
    
        
        
        

        
    
    
    
    </>)
}