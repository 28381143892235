import { Box, Slide, Grid, Avatar, Chip, Tooltip } from '@mui/material';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';

export default function CardTitulo (props) {
  
    const {titulo, chipLabel, icon, help, tooltip, slide, align} = props
    
    return (
      <>
     
      <Slide in={true} direction='right' timeout={slide ? 700 : 0}>
      <Grid container justifyContent={align} alignItems ='center' mt={-1} >
      
      {icon &&
      <Grid item sx={{ml:-1.5, mr:1}} >
      <Avatar sx={{ bgcolor: 'primary.main', width: 34, height: 34}}>
      
      {icon}
      
      </Avatar>
      </Grid>
      }
      
      <Grid item  >
      <span>{titulo}</span>
      </Grid>
      
      {help &&
      
      <Grid item xs={1}>
      <Tooltip title={tooltip} arrow style={{whiteSpace: 'pre-line'}}
      
      >
      <HelpOutlineOutlinedIcon sx={{ml:0.5, mt:0.9, width:'0.7em', height:'0.7em'}}/>
      </Tooltip>
      </Grid>
      
      }
      
      {chipLabel &&
      <Grid item>
      <Chip label={chipLabel} size='small' sx={{fontWeight: '500', ml:0, mb:0.5}} />
      </Grid>
      }
      </Grid>
      </Slide>  
   
      </>
    )
  }

  CardTitulo.defaultProps = {
    align: 'start'
  }