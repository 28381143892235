import { useState, useMemo } from "react";
import { TextField, Autocomplete, FormControl, styled, InputBase, Typography, Select, OutlinedInput, MenuItem, Chip, Box, InputLabel, FormHelperText } from "@mui/material";

function SelectMultipleView (props) {
    //const [selected, setSelected] = useState (props.selected!==undefined && props.opcoes!==undefined ? props.selected : []); // [
    //const [selected2, setSelected2] = useState (props.selected!==undefined && props.opcoes!==undefined ? RetornaOpcao (props.opcoes, props.selected, "id", "nome")  : []);
    const [selected2, setSelected2] = useState ([]);
    const opcoes = props.opcoes!==undefined ? handleOpcoes() : []; 
    

    const [, setRenderiza] = useState (Date.now());

    function handleOpcoes () {
        if (props.opcoes!==undefined) {
          props.opcoes.forEach ((opcao) => {
            if (opcao.hasOwnProperty('nome')) {
              opcao.name = opcao.nome;
              delete opcao.nome;
              delete opcao.segmento;
            }
          })
        }
        
        return props.opcoes;
    }


    useState (() => {
     setRenderiza();
   })

     
   const handleChange = (event, newValue) => {
    console.log (newValue)
    
    //setSelected2 (newValue)
    props.onChange (props.nome, newValue);  
  }

  return (<>
        
        
       {opcoes.length > 0 &&
        <>
        

        
        
       
        <Autocomplete
          sx={{mt:0.3}}
          fullWidth
          multiple
          size='small'  
          id={"select"}
   
          options={opcoes}
          getOptionLabel={(option) => option.name}
          onChange={handleChange}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label={props.titulo}
              
            />)}
                 >
         
        </Autocomplete>  
        
        </>
} 
        </>
    )

} export default SelectMultipleView;

function extrairNumeroDaString(string) {
  const regex = /select-option-(\d+)/;
  const match = string.match(regex);
  if (match) {
    const numero = parseInt(match[1], 10);
    return numero;
  } else {
    return null; // Retorna null se o formato não corresponder
  }
}