import { useState, useRef, useContext } from 'react';
import { QuestoesContext } from '../../../../services/service.questoes.provider';

import * as Prova from './controller.gerar.prova'
import GerarProvaView from './view.gerar.prova';
import { set } from 'date-fns';

export default function ModalGerarProva (props) {
  
  const [open, setOpen] = useState(true);
  const [arquivo, setArquivo] = useState ('');
  const [loading, setLoading] = useState({inicio: false, fim: false, isSuccess: false});
  const { questoesEscolhidas, usuario, disciplinaEscolhida, questoes } = useContext (QuestoesContext);
  const condicoes = useRef ( {fonte: true, ano: true, gabarito: true, resolucao: true, arquivo_word: true} ); 

  const dados = useRef ({id_usuario: usuario.id_usuario, condicoes: {fonte: true, ano: true, gabarito: true, resolucao: true, arquivo_word: true} })
 
  console.count ('Gerar Prova')  
  function handleGerar (e) {
    
    Prova.Gerar (e, setLoading, dados, setOpen, props, arquivo, condicoes, disciplinaEscolhida, questoesEscolhidas, questoes)
 
  }

  function handleClose () {
    console.log (condicoes)
    console.log (dados)
    if (condicoes.current.arquivo_word) {
      Prova.handleCloseComWord (props, dados, setOpen)
    } else Prova.handleCloseSemWord (props, dados, setOpen)
    

  }
  
  return (      
      
      <GerarProvaView open = {open} condicoes = {condicoes} loading = {loading} handleClose = {handleClose} arquivo = {arquivo} setArquivo={setArquivo} handleGerar = {handleGerar} />
      
  );
}