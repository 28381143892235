import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Tooltip, IconButton } from "@mui/material";
import { StyledNavLink, StyledBadge } from './view.navbar.config';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { VariaveisContext } from "../../services/service.variaveis.provider";
import { useTela } from "../../services/service.tela.provider";

export default function BtnNotificacoes () {
    
    const navigate = useNavigate();
    const [tela, setTela] = useTela ();

    const { novasNotificacoes } = useContext( VariaveisContext );
   
    function handleOnClick () {
        setTela ('Notificações')
        navigate ('/notificacoes');
    }
    
    const icon = 
            <Tooltip title={'Notificações'} arrow>
                <IconButton onClick={(e) => {e.preventDefault (); handleOnClick()}}>
                    <StyledNavLink to='/notificacoes' eClicado = {tela.atual === 'Notificações' ? true : false}>
                            <NotificationsIcon fontSize="medium" />
                    </StyledNavLink>
                </IconButton>
              </Tooltip>
    
    return (
        <>
          {novasNotificacoes > 0
            ?
             <StyledBadge badgeContent={novasNotificacoes} color="secondary">
                icon
             </StyledBadge>
            :
                icon 

          }
        </>
    )
}