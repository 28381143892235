import { useState } from "react";
import { Button } from "@mui/material"

import { useLocalStorage } from '../../services/service.hooks';

import Card from '../../views/components/view.card';
import CardTitulo
 from "../../views/components/view.card.titulo";
import HistoryEduOutlinedIcon from '@mui/icons-material/HistoryEduOutlined';

import * as Credito from './controller.credito.js'
import Tabela from "./view.historico.creditos.tabela";
 

export default function HistoricoCredito (props) {
  
  const [loading, setLoading] = useState ({inicio: false, fim: false, isSuccess: false});
  const [creditosLista, setCreditosLista] = useState (-1);
  const [ usuario ] = useLocalStorage ('usuario');

  const handleBtnClick = () => {
      setLoading ({...loading, inicio: true});
      
      Credito.Listar (setLoading, usuario, loading, setCreditosLista)
    }
  

    return (
        <>
          <Card titulo={<CardTitulo icon={<HistoryEduOutlinedIcon />}  titulo = 'Histórico de compras' />}  margin_top={20} fontSizeTitulo="1.2rem" divider mt={-3.0}>
          
                      
                      <Button variant="outlined" onClick={handleBtnClick} size="small" sx={{mt: -0, mb:2}}>Carregar</Button>
                      
                      {/* ************** TABELA ************** */}
                      <Tabela loading = {loading} creditosLista = {creditosLista} />
                      
                    
          </Card>    
        </>
    )
}
