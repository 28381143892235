import { useState, useEffect, useContext } from "react";
import { QuestoesContext } from '../../../services/service.questoes.provider';
import { useLocalStorage } from "../../../services/service.hooks";
import * as ls from '../../../services/service.local-storage'
import * as ModelFiltro from './model.filtro';

import FiltrosView from './view.filtros';

function Filtros (props) {
    
    const [ usuario ] = useLocalStorage ("usuario");
    const { filtros, filtrosEscolhidos, setFiltros, setFiltrosEscolhidos, disciplinaEscolhida } = useContext (QuestoesContext);
    const [loading, setLoading] = useState ({inicio: true, fim: false, isSuccess: false});
      
     
    // CHAMA A API PARA DETERMINAR OS FILTROS
    useEffect(() => {
    
    let a = {};
    let segmento = RetornaFiltros ();
    
    
    segmento.forEach ((filtro) => {
    
    if (filtro.tipo === 'select') {   
        
        ModelFiltro.Listar ({params: {id_usuario: usuario.id_usuario}}, filtro.nome, async (result) => {
                if (result.isSuccess) {
                
                a[filtro.nome] = result.response;
                    setFiltros (a);
                    console.debug ('Filtros carregados: ok');
        } else {
            console.debug ('Erro ao obter filtros:', result.erro + ' - ' + result.msg);
            setLoading ({inicio: false, fim: true, isSuccess: false, erro: result.erro, msg: result.msg})
            props.onFinishLoading (false, result.erro, result.msg);
            return;
        }
        })
    }
        })
        
        setLoading ({inicio: false, fim: true, isSuccess: true})
        props.onFinishLoading (true, '', '');

    }, []);
 
  function BooleanIDRadioBox (valor) {
    
    if (valor==='1') return true;
    if (valor==='2') return false;
    if (valor==='3') return -1;
    return 0;

  }

  function RetornaFiltros () {
    
    if (disciplinaEscolhida.idSegmento === 4) {

        return ModelFiltro.ensino_medio}
   
    if (disciplinaEscolhida.idSegmento === 3) {

        return ModelFiltro.ensino_fundamental2}
  }
   
  function onChange (nome, valores) { 
    
    let a = [];
    
        let b = {...filtrosEscolhidos, [nome]: valores}
        setFiltrosEscolhidos(b);
        ls.Salvar ('filtrosEscolhidos', b)
    
    
}

  const onChangeRadioBox = (nome, valor) => { 
           
        if (valor==='0') {
            
            delete filtrosEscolhidos[nome]
            setFiltrosEscolhidos({...filtrosEscolhidos});
            return
        
        }
        
        let a = [];
        
        if (nome in filtrosEscolhidos) {a = filtrosEscolhidos[nome]
        
            a = BooleanIDRadioBox(valor);
            setFiltrosEscolhidos({...filtrosEscolhidos, [nome]: a});
        
        } else {
        
            setFiltrosEscolhidos ({...filtrosEscolhidos, [nome]: BooleanIDRadioBox(valor)});
        
        }
    }
     
 return (
        <>
        
        <FiltrosView 
            
            loading={loading}
            titulo={props.titulo}
            key={'filtrosview' + props.titulo}
            filtros={{propriedades: RetornaFiltros(), opcoes: filtros, selected: filtrosEscolhidos}}
            onChangeRadioBox={(nome, valor) => onChangeRadioBox (nome, valor)} 
            onChange={(nome, id) => onChange (nome, id)} 
        
        /> 
        </>
    )

} export default Filtros;