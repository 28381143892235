import { Grid, IconButton, Button } from "@mui/material"
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { styled } from '@mui/material';
import { grey } from '@mui/material/colors';


export default function BotaoQuestaoProxima (props) {

    const { onClick, disabled, isMobile } = props
    
    const Btn = styled (IconButton) (({ theme }) => ({
        
        backgroundColor: theme.palette.mode === 'dark' ? grey[800] : grey[400],
        opacity: 0.4,
        '&:hover': {
        backgroundColor: theme.palette.mode === 'dark' ? grey[700] : grey[500],
        opacity: 1,
        
        
    },
      }));
    
    const style = {
       
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)', // centraliza verticalmente
        right: '2%', // 50% - metade do maxWidth + a distância da borda direita
        zIndex: 100000
       
    }  
    return (

        <>
        
        
            
            <Btn style ={style} variant="outlined" color='primary' size="large" onClick = {(e) => onClick(e)} disabled={disabled}>
            
            <KeyboardArrowRightIcon />
            
            </Btn>
            
        
        
        </>
    )
}