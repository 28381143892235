import { dataAtual } from "../../services/service.data";
import { useTheme } from "@mui/material";

// DETERMINA O STATUS DO LINK - SERVE PARA EXIBIÇÃO DO STATUS NA TABELA
export function Status (dados) {
    
    const data_atual = new Date (dataAtual());
    const data_inicio = new Date (dados.inicio);
    const data_termino = new Date (dados.termino)
    
    if (dados.encerrada===true) {
      return 'Encerrada antes'
    }
    
    if (data_termino < data_atual) {
        return 'Encerrada'
    } 
    
    if (data_atual < data_inicio) {
       return 'Não iniciada'
    }
    
    if (data_termino > data_atual && data_inicio <= data_atual) {
        return 'Em andamento'
    }
}

// DETERMINA A COR DO BACKGROUND DO CHIP DE STATUS DO LINK
export function ChipBGColor (dados) {
    
    const theme = useTheme ();

    const data_atual = new Date (dataAtual());
    const data_inicio = new Date (dados.inicio);
    const data_termino = new Date (dados.termino)
    
    if (dados.encerrada===true) {

      return theme.palette.cinza.light

    }

    if (data_termino < data_atual) {
             
        return theme.palette.error.light
    } 
    
    if (data_atual < data_inicio) {
         
        return theme.palette.info.light
    }
    
    if (data_termino > data_atual && data_inicio <= data_atual) {
        
        return theme.palette.success.light
    
    }
    
}

// DETERMINA A COR DO CHIP DE STATUS DO LINK
export function ChipColor () {
    
    const theme = useTheme ();

    if (theme.palette.mode!=='light')
        {return theme.palette.primary.contrastText}
    else {return theme.palette.cinza.dark}
}

// CONFERE SE O BTN ENCERRAR ANTES DEVE ESTAR HABILITADO OU NÂO
export function eBtnEncerrarAntesDisabled (status)  {
  
    if (status === 'Encerrada antes' || status === 'Encerrada' || status === 'Não iniciada') {
      return true
    }
    else return false
}

// CONFERE SE O USUÁRIO POSSUI CRÉDTIOS SUFICIENTES PARA GERAR UM LINK
export function possuiCreditos (usuario, questoes) {

    if (usuario.numcreditos >= questoes) return true;

    return false;
}

