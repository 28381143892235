import { useState, useEffect } from "react";
import { Grid, Typography, Button } from "@mui/material";
import eDataMenorQueAtual, * as Data from '../../../services/service.data'
import { useLocalStorage } from "../../../services/service.hooks";

import * as modelAluno from '../Lista/model.aluno.questao'
import { dataAtual } from "../../../services/service.data";
 
export default function GabaritoNaoLiberado (props) {
    
    
    const data = Data.Extrair (props.resultado.lista_termino)
    
    const [liberado, setLiberado] = useState (false)
    
    function chamarApi () {
        
        let dados = {}
        

        dados.id_link     = '10b07409-2280-434d-a40e-e8e56058e8b2';
        dados.token_aluno = '863197820';
        dados.data        = dataAtual ();

        modelAluno.Questoes (dados, async function (result) {

            if (result.isSuccess) {

                window.location.reload();

            } else {
                
                setLiberado (true)

            }
        })
    }
   
    useEffect(() => {
        // Função que verifica a hora atual
        function verificarHora() {
            const agora = new Date();
            const horas = agora.getHours();
            const minutos = agora.getMinutes();

            // Se for meia-noite e 1 minuto, executa a ação desejada
            if (eDataMenorQueAtual (Data.Extrair(props.resultado.lista_termino)) && horas === 0 && minutos === 1) {
                
                setLiberado (true)
                
            }
        }

        // Define o intervalo para verificar a hora a cada minuto (60000 milissegundos)
        const intervalo = setInterval(verificarHora, 60000);

        // Limpa o intervalo quando o componente for desmontado
        return () => clearInterval(intervalo);
    }, []);
    
     function onClickAcessar (e) {
        e.preventDefault()
        setLiberado (false)   
        chamarApi();
     }

    return (<>
        
        <Grid container display="flex" flexDirection={"column"} alignItems="center" justifyContent="center">
                    
                    
                    
                    <Grid item xs={12} mt={2} >
                        <Typography variant='h6' fontSize={25} color={'primary'}>
                        Gabarito não liberado
                        </Typography>
                    </Grid> 
                    
                    <Grid item xs={12} mt={2} >
                       <Typography variant='body2' fontSize={16}>
                        O gabarito e a resolução das lista será liberada pelo professor no dia
                        </Typography>
                    </Grid>

                    <Grid item xs={12} mt={4} >
                       <Typography variant='h6' fontSize={20} color={'primary'}>
                        {data}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} mt={8} mb={4} >
                       <Typography variant='body2' fontSize={16}>
                        Clique no botão abaixo para obter o gabarito quando estiver liberado
                        </Typography>
                    </Grid>
                    
                    <Button onClick={(e) => onClickAcessar(e)} color='primary' disabled={!liberado}>
            
               ACESSAR GABARITO
            
            </Button>
                </Grid>
        
        </>
        );
}