import { Typography, useTheme } from "@mui/material";

export default function Arvore (props) {
    
    const theme = useTheme ();

    return (


        <>
        
        <Typography color={theme.palette.text.secondary} fontSize={14}>
            {props.arvore}
        </Typography>
        
        </>
    )


}