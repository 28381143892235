import { useState } from 'react';
import { FormLabel, Box, Typography, Radio, RadioGroup, FormControl, FormControlLabel } from '@mui/material';


export default function RadioBoxView (props) {
  
  const [value, setValue] = useState (0)
 
  const handleChange = (event) => {
    event.preventDefault();
    setValue(event.target.value);
    
    props.onChange (props.nome, event.target.value); 
  };
  
  return (
    
      <>
              
      <Box sx={{minWidth: 1,  pt: 1, pl: 2, mt:0.3, mb:-1, borderStyle: 'solid', borderWidth: '0.1px', borderRadius: '4px', borderColor: 'action.selected'}}  >
          <FormControl>
          <FormLabel id="demo-radio-buttons-group-label">{props.titulo}</FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-controlled-radio-buttons-group"
            name={"controlled-radio-buttons-group-" + props.label.nome}
            value={value}
            onChange={handleChange}
            sx={{mt:1}}
          >
          
            {props.label.map ((nome, index) => (<>
            <FormControlLabel value={index.toString()} control={<Radio size='small' />} label={nome} key={nome+index} />
            </>))
    }
          </RadioGroup>
          </FormControl>
    </Box>
    </>
  );
}