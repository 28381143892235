import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import { useState } from 'react';
import { Fragment } from 'react';
import { Typography } from '@mui/material';
export default function DrawerBottom (props) {

    const [open, setOpen] = useState (props.open)
   
    const toggleDrawer = (newOpen) => () => {
        
        setOpen (newOpen);
        props.onClick (false)
      
    };

    const questaounica = (props.totalValidas - props.totalRespondidas) === 1 ? true : false;
    const falta =  questaounica ? 'falta ' : 'faltam '
    const num = (props.totalValidas - props.totalRespondidas)
    const questao = questaounica ? ' questao ' : ' questões '
    const texto = 'Ainda ' + falta + num + questao + ' para responder.'
return (

<>

   
<Fragment key={'bottom'}>
    <Drawer open={open}  anchor={'bottom'} onClose = {toggleDrawer(false)} >
 
    <Typography variant="body1">
       {texto}
    </Typography>
    <Button >Open drawer</Button>
    <Button >Open drawer</Button>
    <Button >Open drawer</Button>
    <Button >Open drawer</Button>
    <Button >Open drawer</Button>
    <Button >Open drawer</Button>
    <Button >Open drawer</Button>
    
    </Drawer>
    </Fragment>
</>

)}