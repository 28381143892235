import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Fade, Grow, Alert, TextField, Box, Button, Grid, Typography, Slide, Collapse, Stack } from "@mui/material";
import Rin from '../../../data/images/Rin2.png'
import Akira from '../../../data/images/Akira2.png'
import Kaito from '../../../data/images/Kaito.png'
import AkiraVoice from '../../../data/sounds/Akira.mp3'
import RinVoice from '../../../data/sounds/Rin.mp3'
import KaitoVoice1 from '../../../data/sounds/Kaito1.mp3'
import KaitoVoice2 from '../../../data/sounds/Kaito2.mp3'
import AkiraVoice2 from '../../../data/sounds/AkiraVoice3.mp3'

//import  './styles.css'
import { useLocalStorage } from "../../../services/service.hooks";

import { COPYRIGHT_NOME, COPYRIGHT_SITE } from '../../../data/config/config';

import FormNomeView from "../../../components/Form/view.form.nome";
import FormSobreNomeView from "../../../components/Form/view.form.sobrenome";
import Botao from "./view.button";
import Copyright from "../../../components/Copyright/view.copyright";

import * as Aluno from './controller.aluno.cadastro';
import { Questoes as AlunoLiberar }  from "../Lista/model.aluno.questao";
import DadosPagamento from "../../../components/Pagamento/modulo.pagamento.dados";
import { dataAtual } from "../../../services/service.data";
import CodigoUUID4 from '../../../services/service.uuid'
import Logo from "../../../components/Login/view.logo";
import CardTitulo from "../../../views/components/view.card";
import LogoAluno from "./view.aluno.logo";
import GenericField from "../../../components/Form/view.form.generic.field";
import BotaoVoltar from "../../../components/BotaoVoltar/view.botao.voltar2";
import Tocar from "../Tocar/Tocar";
import { Get } from "../../../services/service.local-storage";

export default function AlunoCadastro (props) {
    
    
    const [tela, setTela] = useState (0);
    
    
    function handleTelaVoltar () {
        if (tela === 1) setTela(0);
        if (tela === 2) setTela(0);
    }
        
    

    return (<>
        
        {tela !==0 && tela!==3 &&
        <Box sx={{display:'flex', justifyItems: 'flex-start'}} mt={6} ml={3} mb={-10}>
            <BotaoVoltar naomudardetela onClick={() => handleTelaVoltar()} />
        </Box>
}

        <Box  mt={12} display = 'flex' direction='column' justifyContent='center' alignItems='center' >
        
        
        
        <Grid container spacing={2}  direction="row" justifyContent='center' alignItems='center' maxWidth={500} >
        
        <div>
        <Box mt={-8} style={{height: '85vh'}}>
        <CardTitulo mlChildren={-2} mrChildren={-2} mt={-2} >
        
        

        {tela === 0 && <> <Tela0 tela={tela} setTela={(valor) => setTela(valor)}/></>}  
        {tela === 1 && <> <Tela1 tela={tela} setTela={(valor) => setTela(valor)}/></>}  
        {tela === 2 && <> <Tela2 tela={tela} setTela={(valor) => setTela(valor)}/></>}  
        {tela === 3 && <> <TelaToken /></>}  

        <Box mb={-2} mt={1}>
        <CopyrightBottom />
        </Box>

        </CardTitulo>
        </Box>
        </div>

        

        </Grid>
        </Box>

        </>
        );
}

function TelaToken (props) {
    
    const [aluno] = useState (Get ('aluno', 'objeto'))
    const navigate = useNavigate();
    const [play, setPlay] = useState (false)

    useEffect (() => {
        setPlay (true)
    }, [])
    
    function handleIniciarLista () {
        navigate ('/aluno/' + aluno.id_link + '/' + aluno.token, {state: 'token'});
    }
    return (<>
       
       <LogoAluno /> 
        <Box style={{position: 'relative'}} sx={{ width: '100%'}}>
        
        <Personagem personagem={Akira} />
        
        <div>
        <Tocar src={AkiraVoice2} play={play}/>
        <Grow in={true} timeout={3000}>
        
        <Typography fontSize = '1.0em' textAlign={'justify'} sx={{position: 'absolute', top:'67%', left:'5%', right:'5%' }} color= 'background.default' fontWeight={500}>
            <Box sx={{backgroundColor: 'cinza.dark', borderTopLeftRadius: 8, borderTopRightRadius: 8, px:2, py:1}} > 
             ATENÇÃO: anote ou dê print na tela e guarde esse número para poder acessar essa lista em outro dispositivo, caso contrário, as suas respostas serão perdidas.
            </Box>
           
        </Typography>
        
        </Grow>
        </div>
        
        <Box sx={{position: 'absolute', top:'85%', left:'5%', right:'5%', pt: -2, pb: 1 }}>
        <Stack direction='column' alignItems='start' justifyContent='flex-start'>
        <Slide in={true} ease timeout={1000} direction="right" style={{transitionDelay: 500}}>
        <Box sx={{backgroundColor: 'background.transparent', pt: -2, mb:0.5,  width: '100%' }}>
        <TextField size={"small"} fullWidth margin="normal" name={'Token'} label={'Token'} value={aluno.token_aluno} helperText={''} />
        </Box>
        </Slide>
        
        <Slide in={true} ease timeout={1000} direction="right" style={{transitionDelay: 500}}>
            <Button variant="contained" size='small' color="primary"  onClick={() => handleIniciarLista()} >
                INICIAR
            </Button>
        </Slide>
        </Stack>
        </Box>

        </Box>

        
        
       
    </>)
}

function Tela0 (props) {

    const {tela, setTela} = props
    const [play, setPlay] = useState (false)

    useEffect (() => {
        setPlay (true)
    }, [])
    
    return (<>

        <LogoAluno /> 
        <Box style={{position: 'relative'}} sx={{ width: '100%'}}>
        
        <Personagem personagem={Akira} />
        
        <div>
        
        <Grow in={true} timeout={3000}>
        
        <Typography fontSize = '1.2em' textAlign={'justify'} sx={{position: 'absolute', top:'67%', left:'5%', right:'5%' }} color= 'background.default' fontWeight={500}>
            <Box sx={{backgroundColor: 'cinza.dark', borderTopLeftRadius: 8, borderTopRightRadius: 8, px:2, py:1}} > 
             Olá! Eu sou o Akira. Antes de começar, me responda: é a primeira vez que você está acessando essa lista que o professor passou?
            </Box>
            <Tocar src={AkiraVoice} play={play}/>
        </Typography>
        
        </Grow>
        </div>
        
        <Slide in={true} ease timeout={1000} direction="right" style={{transitionDelay: 500}}>
            <Button variant="contained" color="primary" sx={{position: 'absolute', top: '84%', left:'5%'}} onClick={() => setTela(2)}>
                Sim, é a primeira vez!
            </Button>
        </Slide>
        
        <Slide in={true} ease timeout={1000} direction="right" style={{transitionDelay: 800}}>
            <Button variant="contained" color="primary" sx={{position: 'absolute', top: '91%', left:'5%'}} onClick={() => setTela(1)}>
                Não, já acessei essa lista em outro dispositivo!
            </Button>
            </Slide>
        
        </Box>

        
        
       

        

       
        
    </>)
}

function Tela1 (props) {

    const [ dados, setDados ]    = useState ('');
    const [ aluno, setAluno ]    = useLocalStorage ('aluno');
    const [ loading, setLoading] = useState ({inicio: false, fim: false})
    const [btnLigado, setBtnLigado] = useState (false);
    const {tela, setTela} = props
    const navigate = useNavigate();
    const [erro, setErro] = useState (false);
    
    function onChange (e) {
        e.preventDefault ();
        
        if (e.target.value) {
            let valor = e.target.value

            if (valor.length < dados.length) {
                if (tokenCorreto (valor)) {setBtnLigado (true)} else (setBtnLigado(false))
                setDados (valor)
                return
            }
            
            if (valor.length - dados.length > 1) valor = valor.replace(/-/g, ' - ');
            valor = valor.trim();

            let regex = /^[0-9a-z]$/;
            if (!regex.test(valor[valor.length-1])) {
                
                return
            }

            let ultimo = valor [valor.length - 2]
            let penultimo = valor [valor.length - 3]
                        
            if (penultimo === ' ' && ultimo === '-') {
                    
                setDados (dados + ' ' + valor.substring (valor.length - 1, valor.length))
                return
            }

            if (regex.test(penultimo) && ultimo === ' ') {
                    
                setDados (dados + '- ' + valor.substring (valor.length - 1, valor.length))
                return
            }
            
            if (valor.length === 51)  {
                valor = valor.slice(0, -1)
                setBtnLigado (true);
            };
            
            if (valor.length === 8 || valor.length === 15 || valor.length === 22 || valor.length === 29) valor +=  ' - '
            if (valor.length === 9 || valor.length === 16 || valor.length === 23 || valor.length === 30) valor = dados + ' - ' + valor.substring (valor.length - 1, valor.length)
            console.log (tokenCorreto(valor))
            if (tokenCorreto (valor)) {setBtnLigado (true)} else (setBtnLigado(false))
            setDados (valor);   
        }
    
    }

    async function handle (e) {
        
        e.preventDefault();
        setLoading({...loading, inicio: true, fim: false });
        setErro (false)

        let data = {  }
        data.id_link = aluno.id_link;
        data.token_aluno = ajeitarToken(dados);
        
        data.data = dataAtual();
                
        
        AlunoLiberar (data, async function (result) {

            if (result.isSuccess) {
                
                let a = {...aluno, token_aluno: data.token_aluno}
                localStorage.setItem ('aluno', JSON.stringify (a))
                setLoading({...loading, inicio: false, fim: true });
                navigate ('/aluno/' + aluno.id_link + '/' + aluno.token, {state: 'token'});
                
            } else {
                setErro (true)
                setLoading({...loading, inicio: false, fim: true });
            }
        }
    )

    }
    
    return (<>
    
    <LogoAluno />
    <Box style={{position: 'relative'}} sx={{ width: '100%'}}>
        
        <Personagem personagem={Kaito} />
        <Tocar src={KaitoVoice2} play={loading.inicio === false && erro}/>
        <Tocar src={KaitoVoice1} play={loading.inicio === false && !erro}/>

        <div>
        
        <Grow in={true} timeout={3000}>
        
        <Typography fontSize = '1.1em' textAlign={'justify'} sx={{position: 'absolute', top:'67%', left:'5%', right:'5%' }} color= 'background.default' fontWeight={500}>
            <Box sx={{backgroundColor: 'cinza.dark', borderTopLeftRadius: 8, borderTopRightRadius: 8, px:2, py:1}} > 
            {loading.inicio === false && !erro &&
            <span> Olá! Eu sou o Kaito. Digite o número do token que você anotou na primeira vez que acessou essa lista!</span>
        }
            {loading.inicio === false && erro &&
            <Fade in={true} timeout={1500}>
            <span> Hmm... Esse token não pertence a essa lista. Tem certeza que digitou corretamente?</span>
            </Fade>
        }

    {loading.inicio === true &&
            <Fade in={true} timeout={500}>
            <span> Vamos tentar novamente, não é mesmo?</span>
            </Fade>
        }
            </Box>
        </Typography>
        
        </Grow>
        </div>
        
        <Slide in={true} ease timeout={1000} direction="right" style={{transitionDelay: 500}}>
        <Box sx={{position: 'absolute', top:'78%', left:'5%', right:'5%', backgroundColor: 'background.transparent', px:1, pt: -2, pb: 1 }}>
        <TextField size={"small"} required fullWidth autoFocus margin="normal" name={'Token'} label={'Token'} value={dados} onChange={(e) => onChange (e)} helperText={''} />
        </Box>
        </Slide>
        
        <Slide in={true} ease timeout={1000} direction="right" style={{transitionDelay: 800}}>
         <Box sx={{position: 'absolute', top:'88%', left:'5%'}}>
        <Botao botaoLigado ={btnLigado} isLoading = {loading.inicio} handle = {(e) => handle(e)} palavra = {"Enviar"} /> 
        </Box>
            </Slide>
        
       
       
       
        
        </Box>
        
        
        
       
 

    </>)
}

function ajeitarToken (token) {
   let result = token;
   result = result.replace(/ /g, '');
   console.log (result)
   return result;

}
function Personagem (props) {
    
    const { tela } = props;
    
    return (
        <>
        <Box ml={-8} mt={-4} >
        <Fade in={true} timeout={1500}>
        <img style={{ width: '110%', height: 'auto', objectFit: 'contain', transform: 'scaleX(-1)' }} src={props.personagem} alt="Rin" />
        </Fade>
        </Box>
        
        </>
    )
}

function tokenCorreto (valor) {

    let result = true;
    let regex = /^[0-9a-z]$/;
    let size = valor.length
    
    if (size !== 44) return false;

    for (let i = 0; i < 8; i++) {
        if (!regex.test (valor[i])) {
            return false
        }
    }

    for (let i = 11; i < 15; i++) {
        if (!regex.test (valor[i])) {
            return false
        }
    }

    for (let i = 18; i < 22; i++) {
        if (!regex.test (valor[i])) {
            return false
        }
    }

    for (let i = 25; i < 29; i++) {
        if (!regex.test (valor[i])) {
            return false
        }
    }

    for (let i = 32; i < 44; i++) {
        if (!regex.test (valor[i])) {
            return false
        }
    }
    
    if (valor[8] !== ' ' || valor [9] !== '-' || valor [10] !== ' ' ||
        valor[15] !== ' ' || valor [16] !== '-' || valor [17] !== ' ' ||
        valor[22] !== ' ' || valor [23] !== '-' || valor [24] !== ' ' ||
        valor[29] !== ' ' || valor [30] !== '-' || valor [31] !== ' ' 
    ) return false

    return true
    

}
function Tela2 (props) {

    const [ dados, setDados ]    = useState ({nome: '', sobrenome: ''});
    const [ aluno, setAluno ]    = useLocalStorage ('aluno');
    const [ loading, setLoading] = useState ({inicio: false, fim: false})
    const [btnLigado, setBtnLigado] = useState (false);
    const [play, setPlay] = useState (true);
    const {tela, setTela} = props 
    
    function onChangeNome (value) {
        
        if (value) {
           
        setDados ({...dados, nome: value.type ? '' : value})
        
        }
    
    }

    function onChangeSobrenome (value) {
        
        if (value) {
        setDados ({...dados, sobrenome: value.type ? '' : value})
        
        }
     }

     useEffect (() => {

        if (dados.nome !== '' && dados.sobrenome !==  '') {
            setBtnLigado (true)
        } else {
            setBtnLigado (false)

        }
     }, [dados])
        
        
        
     

    async function handle (e) {
        
        e.preventDefault();
        let data = { ...dados }
        data.id_link = aluno.id_link;
        data.token = aluno.token;
        
        data.data_cadastro = dataAtual();
        data.token_aluno = CodigoUUID4 ();
        
        let a = {...aluno, token_aluno: data.token_aluno, nome: dados.nome, sobrenome: dados.sobrenome}
        localStorage.setItem ('aluno', JSON.stringify (a))
        
        
        Aluno.Cadastrar (data, setLoading, setTela)

    }
    
    return (<>
    
    <LogoAluno />
    <Box style={{position: 'relative'}} sx={{ width: '100%'}}>
        
        <Personagem personagem={Rin} />
        
        <Tocar src={RinVoice} play={play}/>
        
        <Grow in={true} timeout={3000}>
       
        
        <Typography fontSize = '1.2em' textAlign={'justify'} sx={{position: 'absolute', top:'67%', left:'5%', right:'5%' }} color={'background.default'}  fontWeight={500}>
        <Box sx={{backgroundColor: 'cinza.dark', borderTopLeftRadius: 8, borderTopRightRadius: 8, px:2, py:1}} > 
            Olá! Eu sou a Rin. Digite o seu nome e sobrenome para iniciar a lista!
            </Box>        
        </Typography>
       
        
        </Grow>
        
        
        <Slide in={true} ease timeout={1000} direction="right" style={{transitionDelay: 500}}>
            <Box sx={{width:'45%', position: 'absolute', top: '78.8%', left:'5%', backgroundColor: 'background.transparent', px:1, pt: -1, pb: 1}}>
            
            <FormNomeView autofocus = {true} onChange = {(value) => onChangeNome (value)} fullWidth={false} />
            
            </Box>
        </Slide>

        <Slide in={true} ease timeout={1000} direction="left" style={{transitionDelay: 500}}>
        <Box sx={{width:'45%', position: 'absolute', top: '78.8%', right:'5%', backgroundColor: 'background.transparent', px:1, pt: -1, pb: 1}}>
            <FormSobreNomeView onChange = {(value) => onChangeSobrenome (value)} />
            </Box>
        </Slide>
        
        
        <Slide in={true} ease timeout={1000} direction="right" style={{transitionDelay: 800}}>
        
            <Box sx={{position: 'absolute', top: '88%', left:'5%'}}>
            <Botao botaoLigado ={btnLigado} isLoading = {false} handle = {(e) => handle(e)} palavra = {"Enviar"} /> 
            </Box>
            
        </Slide>
        
        </Box>

       
        


 

    </>)
}

function CopyrightBottom (props) {


    return (<>
    
        <Copyright nome={COPYRIGHT_NOME} site={COPYRIGHT_SITE} />
    
    </>)
}