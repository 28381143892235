import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button, CircularProgress } from '@mui/material';
import * as modelAluno from '../controller.aluno.lista'
import DrawerBottom from '../../../../components/DrawerBottom/view.drawer.bottom';
import ModalSucessoErro from '../../../../components/ModalSucessoErro/ModalSucessoErro';

export default function Botao (props) {
    
    const [isLoading, setIsLoading] = useState ({inicio: false, fim: false})
    const navigate = useNavigate()
    const location = useLocation();
    const [ openModal, setOpenModal] = useState (false)
    const questaounica = (props.totalValidas - props.totalRespondidas) === 1 ? true : false;
    const falta =  questaounica ? 'falta ' : 'faltam '
    const num = (props.totalValidas - props.totalRespondidas)
    const questao = questaounica ? ' questao ' : ' questões '
    const texto = 'Ainda ' + falta + num + questao + ' para responder.' + '  Deseja enviar a lista pra o professor assim mesmo? Atenção: após o envio da lista não será possível alterar as suas respostas.'

    
    function Enviar () {
        setIsLoading ({...isLoading, inicio: true})
        location.state = 'resultado'

        modelAluno.Encerrar (setIsLoading, props.aluno, navigate, async function (result) {

            if (!result.isSuccess) {
                location.state = ''
                alert ('Erro ao enviar a lista para o professor. Tente novamente mais tarde.')
            
            }
        })
    }
    
    function handle (event) {

        event.preventDefault ();
      
        if (props.totalValidas !== props.totalRespondidas) {

           
           setOpenModal (true)

        } else {

            Enviar();
        } 
       

    }

    function onClickBtnFinalizarAssimMesmo (e) {
        

        e.preventDefault ();
        Enviar()

    }

    return (
        <>
        
        <ModalSucessoErro open = {openModal} titulo = {'Lista incompleta!'} msg = {texto} success={false} onClickBtn2 = {(e) => {onClickBtnFinalizarAssimMesmo(e)}} 
        onClose = {(e) => setOpenModal (e)}
        data = {{
            
            successTipo: "warning",
            errorTipo: "error",
            btn1: {label: "Voltar para a lista", color: "primary"},
            btn2: {mostrar: true, label: "Enviar assim mesmo", color: "secondary"},
            
        }} />
        
        <Button
        
        disableElevation
        size = 'small'
        variant="outlined"
        disabled={isLoading.inicio}
        onClick={(event) => handle (event)}
        
        
        >
        
        {isLoading.inicio ? <CircularProgress color="primary" sx={{ padding: 1 }} /> : 'Finalizar'}
        
        </Button>
        
       
        </>
    )
}