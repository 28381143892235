import { Grid, Link } from '@mui/material';

export default function AlterarParaLogin ({isLoading, alterarParaLogin}) {

    return (
    <>
    
    <Grid item xs sx={{ mb: 4 }}>
    
        <Link href="#" variant="body2" onClick={(e) => alterarParaLogin(e)}>    
        
        {isLoading ? null : "Já tenho uma conta!"}  
        
        </Link>
    
    </Grid>

    </>
    )
}