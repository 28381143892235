// Verifica se a data é fornecida é menor que a data atual. Exemplo: data atual: 25/09/2023, data fornecida: 24/09/2023. Resultado: true 
import { format } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns';

export default function eDataMenorQueAtual (data) {
  // Converte as datas para o formato MM/DD/AAAA
  const dataAtual = new Date().toLocaleDateString('pt-BR');
  var [dia1, mes1, ano1] = data.split("/");
  var [dia2, mes2, ano2] = dataAtual.split("/");
  var novaData1 = new Date(`${mes1}/${dia1}/${ano1}`);
  var novaData2 = new Date(`${mes2}/${dia2}/${ano2}`);
      
  return novaData1 < novaData2 
}

// Pega a data completa com hora, min e segundos no formato genérico e transforma em data no formato DD/MM/AAAA. Exemplo: 2021-09-25T03:00:00.000Z -> 25/09/2021
export function Formatar (data) {
  
  // Extrai apenas a parte de data da string de data e hora
const dateParts = data.split(' ')[0].split('-');

// Reorganiza as partes para criar uma string no formato 'DD/MM/YYYY'
return `${dateParts[2].substring (0,2)}/${dateParts[1]}/${dateParts[0]}`;
}

export const dataAtual = (date) => {
  
  const currentDate = new Date();
  // Formata a data no padrão desejado
  return format (currentDate, "yyyy-MM-dd HH:mm:ss.SSS +00:00");

};

export function Formatada (date, fim)  {
  
  // Formata a data no padrão desejado
  let data = format (date, "yyyy-MM-dd")
  
  if (fim) {

    data = data + " 23:59:59.999 +00:00"

  } else {

    data = data + " 00:00:00.000 +00:00"

  }

    return data

};

export function Extrair(dataHoraString) {
  // Dividindo a string no espaço para separar a data e a hora
  const dataString = dataHoraString.split('T')[0];
  
  // Dividindo a data em ano, mês e dia
  const [ano, mes, dia] = dataString.split('-');
  
  // Retornando a data no formato DD-MM-YYYY
  return `${dia}/${mes}/${ano}`;
}

export const amanha = () => {
  // Obter a data atual
let dataAtual = new Date();

// Criar uma nova data baseada na data atual
let dataSeguinte = new Date(dataAtual);

// Adicionar um dia à data
dataSeguinte.setDate(dataAtual.getDate() + 1);

// Exibir a data seguinte
return dataSeguinte

}






