import { useState } from 'react';

import Card from '../../views/components/view.card';
import CardTitulo from '../../views/components/view.card.titulo';
import { Box, useTheme } from '@mui/material';

import { PieChart,  pieArcLabelClasses } from '@mui/x-charts';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';

export default function PieChartAlunos (props) {
    
    
    const theme = useTheme();

    return (

        <>
        <Box sx={{width: 'auto'}} >
        <Card titulo={<CardTitulo icon={<PeopleAltOutlinedIcon />} titulo = 'Alunos' slide chipLabel={'Total: ' + props.alunos.total} help
        tooltip={<span>O gráfico mostra a quantidade de alunos distribuídos em três categorias:<br/><br/>
        Completa: alunos que fizeram todas as questões e, portanto, finalizaram a lista no prazo.<br/><br/>
        Parcial: alunos que resolveram algumas questões, mas não todas.<br/><br/>
        Em branco: alunos que não resolveram nenhuma questão da lista.<br/><br/>
        A soma dos alunos das três categorias encontra-se na etiqueta "Total:", ou seja, a quantidade total de alunos que acessaram a lista pelo link.
        </span>}
        />}  margin_top={20} fontSizeTitulo="1.2rem" divider >

          
            
            

            <PieChart
                series={[
                  {
                    highlightScope: { faded: 'global', highlighted: 'item' },
                    faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                    arcLabel: (item) => `${item.value}`,
                    arcLabelMinAngle: 30,
                    data: [
                      { id: 0, value: props.alunos.queFinalizaram, label: (location) => {
                        if (location==='legend') return 'Completa'
                        if (location==='tooltip') return 'Alunos que resolveram todas as questões da lista' 
                      },
                      color: theme.palette.chart.verde
                      },
                      
                      { id: 1, value: props.alunos.queComecaram, label: (location) => {
                        if (location==='legend') return 'Parcial'
                        if (location==='tooltip') return 'Alunos que resolverem apenas algumas questões da lista'
                      },
                      color: theme.palette.chart.amarelo
                      },
                      
                      { id: 2, value: props.alunos.queNaoComecaram, label: (location) => {
                        if (location==='legend') return 'Em branco'
                        if (location==='tooltip') return 'Alunos que não resolveram nenhuma questão da lista' 
                      },
                      color: theme.palette.chart.vermelho
                      },
                    ],
                  },
                ]}

                sx={{
                  [`& .${pieArcLabelClasses.root}`]: {
                    fill: 'white',
                    
                  },
                }}

                
                height={200}
              />
          
            
        </Card>
        </Box>
        </>
    )
}   




