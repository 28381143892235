import { useState } from 'react';
import * as Data from '../../services/service.data';
import HtmlPainel from '../../components/HtmlPainel/view.html.panel';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { Typography, Button, Box } from '@mui/material';
 
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function NotificacoesCard(props) {
  
  const data = props.notificacoes.data_envio
  
    const noti = props.notificacoes.notificacao; 
    const [expanded, setExpanded] = useState('');
  
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  
        
   function handleBtnClick () {
     window.open(noti.botao_link, '_blank');
   }


  return (
    <div > 
      <Accordion sx={{mt: 2}} expanded={expanded === 'panel' + props.key} onChange={handleChange('panel' + props.key)}>
        <AccordionSummary aria-controls={"panel" + props.key + "d-content"} id={"panel" + props.key + "d-header"}>
          <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%'}}>
          <Typography variant="subtitle1">{noti.titulo}</Typography>
          <Typography sx={{mt:0.6}} variant="caption">{Data.Formatar (data)}</Typography>
          </Box>  
          
        </AccordionSummary>
        <AccordionDetails>
          
          <Typography sx={{mb: 0}}>
            <HtmlPainel html={noti.mensagem} />
          </Typography>
          
          {noti.botao &&
            <Button variant="contained" onClick={handleBtnClick} size="medium" sx={{mt: 4}}>{noti.botao_titulo}</Button>
          }
        </AccordionDetails>
      </Accordion>
      
    </div>
  );
}