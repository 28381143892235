import { useState } from 'react';
import { BarChart, barLabelClasses } from '@mui/x-charts';
import Card from '../../../views/components/view.card';
import { Box, useTheme } from '@mui/material';
import StackedLineChartOutlinedIcon from '@mui/icons-material/StackedLineChartOutlined';
import CardTitulo from '../../../views/components/view.card.titulo';

export default function BarChartPctNotas (props) {
    
    const [dataset] = useState (Dataset(props.alunos.dados))
    const theme = useTheme ();

    return (

        <>
        <Box sx={{width: 'auto'}}>
        <Card titulo={<CardTitulo titulo='Intervalo entre notas' icon={<StackedLineChartOutlinedIcon />} help slide tooltip={<span>Porcentagem da distribuição das notas entre os intervalos<br /><br />0 - 5: 
         notas entre 0 e 4,9<br/>5 - 7:  notas entre 5 e 6,9<br/>7 - 10: notas entre 7 e 10<br/><br/>Útil para verificar rapidamente o rendimento da turma na lista.
        </span>}/>}  margin_top={20} fontSizeTitulo="1.2rem" divider >

            <Box sx={{mt:-5}}>
          
            <BarChart 
              dataset={dataset}
              yAxis={[{categoryGapRatio: 0.4, scaleType: 'band', dataKey: 'y',  

                colorMap: {
                  type: 'ordinal',
                  thresholds: ['0 - 5', '5 - 7', '7 - 10'],
                  colors: [theme.palette.chart.verde, theme.palette.chart.amarelo, theme.palette.chart.vermelho],
                }
              }]}
              series={[{ dataKey: 'x', ry: 5, valueFormatter: (v, { dataIndex }) => {
                   
                let valor1 = '0'
                let valor2 = '0'
                if (dataIndex===0) {
                  valor1 = '7'
                  valor2 = '10'
                }
                if (dataIndex===1) {
                  valor1 = '5'
                  valor2 = '6,9'
                }
                if (dataIndex===2) {
                  valor1 = '0'
                  valor2 = '4,9'
                }
                  
                  return (
                    
                   <span>{dataset[dataIndex].x + '% das notas estão entre ' + valor1 + ' e ' + valor2}</span>
                  )
                  
                  
                },

                }]}
              layout="horizontal"
              bottomAxis={{ disableTicks: true, label: 'Porcentagem %' }}
              leftAxis={{ disableTicks: true,
                


               }}

               
              tooltip={{trigger: 'axis'}}
              grid={{ vertical: true }}
              borderRadius={7}
              barLabel={(item, context) => {
                if (item.value > 2) {
                return item.value + '%'
                }
              }}
              sx={{mt:0, pl:0,
                [`& .${barLabelClasses.root}`]: {
                  fill: 'white',
                  
                },
              }}                                       
              {...chartSetting}
            />
                
            </Box> 
        </Card>
        </Box>
        </>
    )
}   

const chartSetting = {
    
    
  
  height: 200,
};

function Dataset (alunos) {

let nota1 = 0;
let nota2 = 0;
let nota3 = 0;
let total = alunos.length

for (let aluno of alunos) {
  if (parseFloat(aluno.nota) >= 0 && parseFloat(aluno.nota) < 5) {
    nota1++;
  } else if (parseFloat(aluno.nota) >= 5 && parseFloat(aluno.nota) < 7) {
    nota2++;
  } else if (parseFloat(aluno.nota) >= 7 && parseFloat(aluno.nota) <= 10) {
    nota3++;
  }
}

let pct1 = (nota1 / total) * 100
let pct2 = (nota2 / total) * 100
let pct3 = (nota3 / total) * 100

return [
  { y: '7 - 10', x: parseInt(pct3.toFixed(0)) },
  { y: '5 - 7', x: parseInt(pct2.toFixed(0)) },
  { y: '0 - 5', x: parseInt(pct1.toFixed(0)) },
  
]
}
