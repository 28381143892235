import { chamarApiWord, chamarApi } from '../../../../services/service.api';

export function Gerar (data, callback) {
    
    const getResult = async () => {

        try {
            console.debug ('Gerando prova...');
            
            let endpoint = data.condicoes.arquivo_word ? 'gerarProvaComWord' : 'gerarProvaSemWord'
            
            let result;

            {data.condicoes.arquivo_word ?
                 result = await chamarApiWord ({type: 'post', endpoint: endpoint, data: data}) : // chama a API :
                 result = await chamarApi ({type: 'post', endpoint: endpoint, data: data})
            }
            
            callback (result);

        } catch (error) {
            
            callback (error);

        } 
    }
        
    getResult();

} 

export function Editar (data, callback) {
    
    const getResult = async () => {

        try {
            console.debug ('Atualizando prova...');
            
            const result = await chamarApi ({type: 'patch', endpoint: 'editarProva', data: data})  // chama a API :
                 
            
            
            callback (result);

        } catch (error) {
            
            callback (error);

        } 
    }
        
    getResult();

}

